import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { DashboardFilters, FilterTypes, sortByErrorTsDesc } from '../../../helpers/statusCardHelpers'
import { useSearchParams } from '../../../hooks'
import { LogErrorStatus } from '../../../interfaces'
import ServiceDashboard from './ServiceDashboard'
import { useObsStore } from '../../../store'
export const ServicePage: React.FC = () => {
  const [currentStatusFilterValue, setCurrentStatusFilterValue] = useState<DashboardFilters>(FilterTypes.ALL)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = useSearchParams()

  const servicesData = useObsStore((state) => state.services)

  const services = useMemo(() => (servicesData ? Object.values(servicesData).sort(sortByErrorTsDesc) : []), [servicesData])
  
  useEffect(() => {
    const statusFilter = searchParams.get('status')?.toUpperCase() as FilterTypes
    if (statusFilter) {
      if ([FilterTypes.ALL, LogErrorStatus.ERROR, LogErrorStatus.OK, FilterTypes.OTHER].includes(statusFilter)) {
        setCurrentStatusFilterValue(statusFilter)
      }
    } else {
      searchParams.set('status', FilterTypes.ALL)
      navigate({ ...location, search: searchParams.toString() })
    }
  }, [setCurrentStatusFilterValue, searchParams, navigate, location])

  const servicesWithError = useMemo(
    () => services.filter((svc) => svc.logErrorStatus === LogErrorStatus.ERROR),
    [services]
  )
  const servicesWithOk = useMemo(
    () => services.filter((svc) => svc.logErrorStatus === LogErrorStatus.OK),
    [services]
  )
  const servicesWithOther = useMemo(
    () =>
      services
        .filter((svc) => svc.logErrorStatus !== LogErrorStatus.ERROR && svc.logErrorStatus !== LogErrorStatus.OK),
    [services]
  )
  return (
    <ServiceDashboard
      servicesWithOk={servicesWithOk}
      servicesWithError={servicesWithError}
      servicesWithOther={servicesWithOther}
      filter={currentStatusFilterValue}
    />
  )
}
