import { useCallback, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useObsStore } from '../../store'
import { Tab } from '@headlessui/react'
import { ServiceDetails } from '../../components/service/ServiceDetails'
import { ServiceConfig } from '../../components/service/ServiceConfig'
import { ServiceHealthHistory } from '../../components/service/ServiceHealthHistory'
import { NoData } from '../../components/common/NoData'
import { ServiceReleaseHistory } from '../../components/service/ServiceReleases'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'

const classNames = (...classes: any) => classes.filter(Boolean).join(' ')

const tabClasses = ({ selected }: any) =>
  classNames(
    'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
    selected
      ? 'dark:bg-white dark:text-gray-900 bg-gray-700 text-white shadow'
      : 'text-black dark:text-white dark:hover:bg-white/[0.12] hover:bg-gray-800/[0.12]'
  )

const Service: React.FC = () => {
  const { name } = useParams<{ name: string }>()

  const serviceName = name ?? ''

  const { service, logGroupsData } = useObsStore(
    useCallback((state) => ({ service: state.services?.[serviceName], logGroupsData: state.logGroups }), [serviceName])
  )

  const logGroups = useMemo(
    () => (logGroupsData ? Object.values(logGroupsData).filter((item) => item.serviceName === serviceName) : []),
    [logGroupsData, serviceName]
  )

  const user = useObsStore((state) => state.user)
  const showConfig = useMemo(() => user && user.teams.includes(service?.team ?? ''), [service, user])

  if (!service) {
    return (
      <div className='pt-10 px-10 h-screen-no-scroll'>
        <NoData />
      </div>
    )
  }

  return (
    <div className='pt-10 px-10 h-screen-no-scroll'>
      <h2 className='flex flex-inline items-center text-left mb-4 text-2xl font-bold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white'>
        <Link to='/services'>
          <ArrowLeftIcon className='w-8 h-8 mr-2 cursor-pointer' />
        </Link>
        <span>{serviceName}</span>
      </h2>

      <Tab.Group>
        <Tab.List className='flex flex-col md:flex-row space-x-1 rounded-xl p-1'>
          <Tab className={tabClasses}>Details</Tab>
          <Tab className={tabClasses}>Health History</Tab>
          <Tab className={tabClasses}>Release History (CD)</Tab>
          {showConfig && <Tab className={tabClasses}>Config</Tab>}
        </Tab.List>
        <Tab.Panels className='mt-2'>
          <Tab.Panel>
            <ServiceDetails service={service} logGroups={logGroups} />
          </Tab.Panel>
          <Tab.Panel>
            <ServiceHealthHistory serviceName={serviceName} />
          </Tab.Panel>
          <Tab.Panel>
            <ServiceReleaseHistory serviceName={serviceName} />
          </Tab.Panel>
          <Tab.Panel>
            <ServiceConfig serviceName={serviceName} config={service?.config ?? {}} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default Service
