import { useLocation, useNavigate } from 'react-router-dom'
import { useSearchParams } from '../../../hooks'
import { IService, LogErrorStatus } from '../../../interfaces'
import { ServiceDashboardCard } from '../../../components/service/ServiceDashboardCard'
import tw from 'tailwind-styled-components'
import { DashboardFilters, FilterTypes } from '../../../helpers/statusCardHelpers'
import { useCallback } from 'react'
import { StatusFilter } from '../../../components/filters/StatusFilter'

export interface IServiceDashboardProps {
  filter: DashboardFilters
  servicesWithError: IService[]
  servicesWithOk: IService[]
  servicesWithOther: IService[]
}

const ServiceDashboard: React.FC<IServiceDashboardProps> = ({
  filter,
  servicesWithOk,
  servicesWithError,
  servicesWithOther
}) => {
  const location = useLocation()
  const searchParams = useSearchParams()
  const navigate = useNavigate()

  const changeFilter = useCallback(
    (value: string) => {
      searchParams.set('status', value)
      navigate({ ...location, search: searchParams.toString() })
    },
    [location, navigate, searchParams]
  )

  return (
    <div className='pt-10 px-10 h-screen-no-scroll'>
      <h2 className='text-left mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white'>
        Services
      </h2>
      <div className='flex flex-row w-48 text-left'>
        <StatusFilter value={filter} valueUpdated={changeFilter} />
      </div>
      {[FilterTypes.ALL, LogErrorStatus.ERROR].includes(filter) && (
        <div className='pt-4'>
          <ServicesGridHeader>ERROR</ServicesGridHeader>
          <ServicesGrid>
            {servicesWithError.length > 0 ? (
              servicesWithError.map((service) => (
                <ServiceDashboardCard key={service.serviceName} service={service} open />
              ))
            ) : (
              <p className='dark:text-white'>No Services in ERROR</p>
            )}
          </ServicesGrid>
        </div>
      )}
      {[FilterTypes.ALL, LogErrorStatus.OK].includes(filter) && (
        <div className='pt-4'>
          <ServicesGridHeader>OK</ServicesGridHeader>
          <ServicesGrid>
            {servicesWithOk.length > 0 ? (
              servicesWithOk.map((service) => <ServiceDashboardCard key={service.serviceName} service={service} />)
            ) : (
              <p className='dark:text-white'>No Services in OK</p>
            )}
          </ServicesGrid>
        </div>
      )}
      {(filter === FilterTypes.ALL || filter === FilterTypes.OTHER) && (
        <div className='pt-4'>
          <ServicesGridHeader>Other Statuses</ServicesGridHeader>
          <ServicesGrid>
            {servicesWithOther.length > 0 ? (
              servicesWithOther.map((service) => <ServiceDashboardCard key={service.serviceName} service={service} />)
            ) : (
              <p className='dark:text-white'>No Other Service Statuses</p>
            )}
          </ServicesGrid>
        </div>
      )}
    </div>
  )
}

const ServicesGridHeader = tw.h3`
  text-left 
  text-2xl 
  font-bold 
  dark:text-white
`

const ServicesGrid = tw.div`
  grid 
  grid-cols-1 
  sm:grid-cols-2 
  lg:grid-cols-3 
  xl:grid-cols-4 
  gap-4 
  justify-items-center 
  border-t-2 
  pt-4
`

export default ServiceDashboard
