import { ChangeType, HealthStatus, IService } from '../../../interfaces'
import { Link } from 'react-router-dom'
import { ArrowsPointingOutIcon, BookmarkIcon } from '@heroicons/react/20/solid'
import { ColorText } from '../../common/ColorText'
import { Card } from '../../common/Card'
import { getBgColor, getLocaleTimestamp, getStatusColor } from '../../../helpers/statusCardHelpers'
import { Divider } from '../../common/Divider'
import { useObsStore } from '../../../store'
import bffApi from '../../../services/bffApi'

export interface IServiceDashboardCardProps {
  service: IService
  open?: boolean
}

export const ServiceDashboardCard: React.FC<IServiceDashboardCardProps> = ({ service, open = false }) => {
  const [user, bookmarks, appendServices] = useObsStore((state) => [state.user, state.bookmarks, state.appendServices])
  const isBookmark = (user?.showServices || []).includes(service.serviceName)
  const toggleBookmark = async (e: any) => {
    e.preventDefault()
    let newShowBookmarks: string[]
    if (isBookmark) {
      if (bookmarks) {
        appendServices([{ changeType: ChangeType.DELETE, service: { serviceName: service.serviceName } }])
      }
      newShowBookmarks = (user?.showServices || []).filter((serviceName: string) => service.serviceName !== serviceName)
    } else {
      newShowBookmarks = (user?.showServices || []).concat(service.serviceName)
    }
    await bffApi.patchUser(newShowBookmarks)
  }
  const healthTimestamp = service.lastHealthTS ? getLocaleTimestamp(service.lastHealthTS, true) : 'Unknown'
  const logTimestamp = service.lastLogGroupTS ? getLocaleTimestamp(service.lastLogGroupTS, true) : 'Unknown'

  const currLogStatus = service.logErrorStatus == null ? HealthStatus.UNKNOWN : service.logErrorStatus
  const currHealthStatus = service.healthStatus?.status
  const logStatusColor = getStatusColor(currLogStatus)
  const healthStatusColor = getStatusColor(currHealthStatus)
  const shortSha = service.currentVersion?.commitSha?.substr(0, 7)
  return (
    <Card
      open={open}
      collapsible={true}
      title={service.serviceName}
      color={getBgColor(service.logErrorStatus)}
      style={{ width: 'calc(25vh + 25px)' }}
      IconLeft={
        <BookmarkIcon
          color={`${isBookmark ? 'red' : 'white'}`}
          onClick={toggleBookmark}
          className='w-10 mr-2 stroke-gray-200 shadow-lg'
        />
      }
    >
      <div className='p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-xl dark:bg-gray-800 dark:border-gray-700'>
        <div className='text-left'>
          <p className='mb-3 font-normal text-gray-700 dark:text-gray-300'>
            <b>Health Status: </b>
            <ColorText color={healthStatusColor} weight='bold' uppercase>
              {currHealthStatus}
            </ColorText>
          </p>

          <p className='mb-3 font-normal text-gray-700 dark:text-gray-300'>
            <b>Log Status: </b>
            <ColorText color={logStatusColor} weight='bold' uppercase>
              {currLogStatus}
            </ColorText>
          </p>

          <p className='mb-3 font-normal text-gray-700 dark:text-gray-300'>
            <b>Last Health Update at: </b>
            <span>{healthTimestamp}</span>
          </p>

          <p className='mb-3 font-normal text-gray-700 dark:text-gray-300'>
            <b>Last Log Update at: </b>
            <span>{logTimestamp}</span>
          </p>
          <p className='mb-3 font-normal text-gray-700 dark:text-gray-300'>
            <b>Version / Commit: </b>
            <span>{shortSha}</span>
          </p>
          <Divider />
        </div>
        <div className='flex space-between'>
          <span className='w-full text-center font-bold text-gray-700 dark:text-gray-200'>Expand for more info</span>
          {/* TODO: does this to need to be there? */}
          <Link to={service.serviceName}>
            <ArrowsPointingOutIcon className='text-gray-900 dark:text-gray-200 w-5 h-5' />
          </Link>
        </div>
      </div>
    </Card>
  )
}
