import { ILogEventsGroup, ILogGroup, IService, HealthStatus, LogErrorStatus } from '../interfaces'

// Helper functions
export const getStatusColor = (status: LogErrorStatus | HealthStatus) => {
  switch (status) {
    case HealthStatus.DOWN:
    case LogErrorStatus.ERROR:
      return 'red'
    case HealthStatus.UP:
    case LogErrorStatus.OK:
      return 'green'
    case HealthStatus.OUT_OF_SERVICE:
      return 'gray'
    case HealthStatus.UNKNOWN:
    default:
      return 'black'
  }
}

export const getBgColor = (status?: LogErrorStatus) => {
  switch (status) {
    case 'OK':
      return 'bg-lime-700'
    case 'ERROR':
      return 'bg-red-700'
    default:
      return 'bg-gray-800'
  }
}

export const prettyPrintJSONString = (jsonStr: string) => JSON.stringify(JSON.parse(jsonStr), null, 2)

export const getLocaleTimestamp = (ts: string | number, parse?: boolean) =>
  parse ? new Date(Date.parse(ts + '')).toLocaleString() : new Date(ts).toLocaleString()

export const sortByTsDesc = (x: string | undefined, y: string | undefined) => {
  const yDate = y == null ? 0 : Date.parse(y)
  const xDate = x == null ? 0 : Date.parse(x)
  return yDate - xDate
}

export const sortByErrorTsDesc = (x: ILogGroup | IService, y: ILogGroup | IService) => {
  return sortByTsDesc(x.lastLogEventsWithErrorsTS, y.lastLogEventsWithErrorsTS)
}

export const sortByUpdateDesc = (
  x: ILogGroup | IService | ILogEventsGroup,
  y: ILogGroup | IService | ILogEventsGroup
) => {
  return sortByTsDesc(x.lastUpdateTS, y.lastUpdateTS)
}

export const sortDesc = (arr: ILogGroup[] | IService[]) => arr.sort(sortByErrorTsDesc)

export enum FilterTypes {
  ALL = 'ALL',
  OTHER = 'OTHER'
}

export type DashboardFilters = FilterTypes | LogErrorStatus
