import { HealthStatus, ILogGroup } from '../../../interfaces'
import { Link } from 'react-router-dom'
import { ArrowsPointingOutIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { ColorText } from '../../common/ColorText'
import { Card } from '../../common/Card'
import tw from 'tailwind-styled-components'
import { getBgColor, getLocaleTimestamp, getStatusColor } from '../../../helpers/statusCardHelpers'
import { Divider } from '../../common/Divider'
import { shortenLogsName } from '../../../utils'

export interface ILogsDashboardCardProps {
  logGroup: ILogGroup
  open?: boolean
}

export const LogsDashboardCard: React.FC<ILogsDashboardCardProps> = ({ logGroup, open = false }) => {
  const logGroupShortName = shortenLogsName(logGroup.logGroupName)!
  const currStatus = logGroup.logErrorStatus == null ? HealthStatus.UNKNOWN : logGroup.logErrorStatus
  const statusColor = getStatusColor(currStatus)
  const timestamp = getLocaleTimestamp(logGroup.lastUpdateTS, true)

  const linkTarget = `/loggroups/${encodeURIComponent(logGroup.logGroupId)}`

  return (
    <Card
      open={open}
      collapsible={true}
      title={logGroupShortName}
      color={getBgColor(logGroup.logErrorStatus)}
      style={{ width: 'calc(25vh + 25px)' }}
    >
      <div className='p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-xl dark:bg-gray-800 dark:border-gray-700'>
        <div className='text-left'>
          <p className='mb-3 font-normal text-gray-700 dark:text-gray-300'>
            <b>Status: </b>
            <ColorText color={statusColor} weight='bold' uppercase>
              {currStatus}
            </ColorText>
          </p>

          <LogsCardItem>
            <b>Service: </b>
            <span className='font-bold'>{logGroup.serviceName}</span>
          </LogsCardItem>

          <LogsCardItem>
            <b>AWS Region: </b>
            <span className='italic'>{logGroup.awsRegion}</span>
          </LogsCardItem>

          <LogsCardItem>
            <b>AWS Account: </b>
            <span className='italic'>{logGroup.awsAccount}</span>
          </LogsCardItem>

          <p className='mb-3 text-gray-700 dark:text-gray-300 flex items-center'>
            <span className='font-bold'>Open Log Group in AWS Console:</span>
            <a
              href={logGroup.logGroupLink}
              target='_blank'
              rel='noreferrer'
              className='inline-flex items-center font-medium text-gray-700 dark:text-gray-300 hover:underline'
            >
              <ArrowTopRightOnSquareIcon className='text-gray-900 dark:text-gray-200 w-5 h-5' />
            </a>
          </p>

          <LogsCardItem>
            <b>Last Update at: </b>
            <span>{timestamp}</span>
          </LogsCardItem>

          <LogsCardItem>
            <b>Last Log Event with Error at: </b>
            <span>
              {logGroup.lastLogEventsWithErrorsTS ? getLocaleTimestamp(logGroup.lastLogEventsWithErrorsTS) : 'Unknown'}
            </span>
          </LogsCardItem>

          <Divider />
        </div>

        <div className='flex space-between'>
          <span className='w-full text-center font-bold text-gray-700 dark:text-gray-200'>Expand for more info</span>
          <Link to={linkTarget}>
            <ArrowsPointingOutIcon className='text-gray-900 dark:text-gray-200 w-5 h-5' />
          </Link>
        </div>
      </div>
    </Card>
  )
}

const LogsCardItem = tw.p`
  mb-3 
  font-normal 
  text-gray-700 
  dark:text-gray-300
`
