import React, { Fragment, useMemo, useState } from 'react'
import { Disclosure, Menu, Switch, Transition } from '@headlessui/react'
import { BellIcon, BookmarkIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../../../logo.svg'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../config/auth'
import { useObsStore } from '../../../store'
import { initializeDashboardData } from '../../../services/bffApi/initData'
import tw from 'tailwind-styled-components'
import { executeWithToast, IExecuteWithToastOptions } from '../../../helpers/executionUtils'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const noop = () => {}

const toggleConfig: IExecuteWithToastOptions = {
  loadingMessage: 'Refreshing Dashboard',
  failureMessage: 'Could not refresh Dashboard'
}

const IconBtn = tw.button`
  relative
  disabled:cursor-not-allowed 
  text-center
  font-medium rounded-lg
`

const NotificationBadge = tw.span`
  inline-flex 
  absolute 
  -top-1 
  -right-1 
  justify-center 
  items-center 
  w-4 
  h-4 
  text-xs 
  font-bold 
  text-white 
  bg-red-500 
  rounded-full 
  border-2 
  border-gray-900
`

const BookmarkButton: React.FC<{ clickHandler: () => void; loading: boolean; bookmarks: boolean }> = ({
  clickHandler,
  loading,
  bookmarks
}) => {
  return (
    <IconBtn disabled={loading} onClick={clickHandler}>
      <span className='sr-only'>Toggle bookmarks</span>
      <BookmarkIcon color={bookmarks ? 'red' : 'white'} className='w-7' />
    </IconBtn>
  )
}

const NotificationsButton: React.FC<{ badge: boolean; clickHandler: () => void }> = ({ clickHandler, badge }) => {
  return (
    <IconBtn onClick={clickHandler}>
      <span className='sr-only'>Open notifications</span>
      <BellIcon className='w-7 text-white'></BellIcon>
      {badge && <NotificationBadge />}
    </IconBtn>
  )
}

export const Header = () => {
  const { signOut, loggedIn } = useAuth()
  const [bookmarks, toggleBookmarks, toggleNotifications, notifications, clear] = useObsStore((state) => [
    state.bookmarks,
    state.toggleBookmarks,
    state.toggleNotifications,
    state.notifications,
    state.clear
  ])
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const showBadge = useMemo(() => notifications.length > 0, [notifications.length])

  const handleBookmarks = () => {
    if (!loggedIn) {
      return
    }

    toggleBookmarks()
    setLoadingData(true)
    executeWithToast(() => initializeDashboardData(), toggleConfig).then(() => setLoadingData(false))
  }

  const onLogout = async () => {
    await signOut()
    clear()
  }

  const navigation = [
    { name: 'Services', href: '/services', current: true },
    { name: 'Log groups', href: '/loggroups', current: false },
    { name: 'Profile', href: '/profile', current: false },
    { name: 'Sign Out', href: '#', current: false, onClick: onLogout }
  ]

  return (
    <Disclosure as='nav' className='bg-gray-800'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              {loggedIn && (
                <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                  {/* Mobile menu button*/}
                  <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XMarkIcon className='block h-6 w-6 text-white' aria-hidden='true' />
                    ) : (
                      <Bars3Icon className='block h-6 w-6 text-white' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
              )}
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='flex flex-shrink-0 items-center'>
                  <h1 className='block w-auto text-white text-3xl xs-hidden sm:hidden lg:block'>
                    Observability Dashboard
                  </h1>
                  <h1 className='block w-auto text-white text-3xl block lg:hidden'>Observability</h1>
                  <div className='pl-5'>
                    <DarkModeToggle />
                  </div>
                </div>
              </div>
              {!loggedIn ? (
                <img className='hidden h-8 w-auto lg:block' src={logo} alt='Porsche Digital' />
              ) : (
                <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                  {loggedIn && (
                    <BookmarkButton bookmarks={bookmarks} loading={loadingData} clickHandler={handleBookmarks} />
                  )}
                  {loggedIn && <NotificationsButton clickHandler={toggleNotifications} badge={showBadge} />}
                  {/* Profile dropdown */}
                  <Menu as='div' className='relative ml-3'>
                    <Menu.Button className='hidden sm:block flex rounded-full text-sm'>
                      <span className='sr-only'>Open user menu</span>
                      <span className='font-semibold text-white'>Menu</span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        {navigation.map(({ name, href, onClick }) => (
                          <Menu.Item key={name}>
                            {({ active }) => (
                              <Link
                                to={href}
                                onClick={onClick ? onClick : noop}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                {name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
          </div>
          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 px-2 pt-2 pb-3'>
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.href}
                  onClick={item.onClick ? item.onClick : noop}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

const DarkModeToggle: React.FC = () => {
  const [darkMode, toggleDarkMode] = useObsStore((state) => [state.darkMode, state.toggleDarkMode])
  return (
    <div className='flex my-auto align-middle'>
      <Switch.Group>
        <Switch
          checked={darkMode}
          onChange={toggleDarkMode}
          className='bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full'
        >
          <span className='sr-only'>Set dark mode</span>
          <span className='translate-x-1 dark:translate-x-6 inline-block h-4 w-4 transform rounded-full bg-gray-800 transition' />
        </Switch>
        <Switch.Label className='ml-4 text-white'>{darkMode ? 'Dark mode' : 'Light mode'}</Switch.Label>
      </Switch.Group>
    </div>
  )
}
