import { useEffect, useMemo, useState } from 'react'
import { DashboardFilters, FilterTypes, sortByErrorTsDesc } from '../../../helpers/statusCardHelpers'
import { useSearchParams } from '../../../hooks'
import { LogErrorStatus } from '../../../interfaces'
import { useObsStore } from '../../../store'
import LogGroupDashboard from './LogGroupDashboard'

const LogGroupsPage: React.FC = () => {
  const [servicesData, logGroupsData] = useObsStore((state) => [state.services, state.logGroups])

  const svcNames = useMemo(
    () => (servicesData ? Object.values(servicesData).map((item) => item.serviceName) : []),
    [servicesData]
  )
  const logGroups = useMemo(() => (logGroupsData ? Object.values(logGroupsData) : []), [logGroupsData])

  const [currentStatusFilterValue, setCurrentStatusFilterValue] = useState<DashboardFilters>(FilterTypes.ALL)
  const [currentServiceFilterValue, setCurrentServiceFilterValue] = useState<string>(FilterTypes.ALL)

  const searchParams = useSearchParams()

  useEffect(() => {
    let statusFilter = searchParams.get('status') as DashboardFilters
    if (statusFilter) {
      statusFilter = statusFilter.toUpperCase() as DashboardFilters
      if ([FilterTypes.ALL, LogErrorStatus.ERROR, LogErrorStatus.OK, FilterTypes.OTHER].includes(statusFilter)) {
        setCurrentStatusFilterValue(statusFilter)
      }
    }

    let serviceFilter = searchParams.get('service')
    if (serviceFilter && svcNames.includes(serviceFilter)) {
      setCurrentServiceFilterValue(serviceFilter)
    }
  }, [searchParams, svcNames])

  const logGroupsWithError = useMemo(
    () =>
      logGroups
        .filter(
          (logGroup) =>
            logGroup.logErrorStatus === LogErrorStatus.ERROR &&
            [FilterTypes.ALL, logGroup.serviceName].includes(currentServiceFilterValue)
        )
        .sort(sortByErrorTsDesc),
    [currentServiceFilterValue, logGroups]
  )
  const logGroupsWithOk = useMemo(
    () =>
      logGroups
        .filter(
          (logGroup) =>
            logGroup.logErrorStatus === LogErrorStatus.OK &&
            [FilterTypes.ALL, logGroup.serviceName].includes(currentServiceFilterValue)
        )
        .sort(sortByErrorTsDesc),
    [currentServiceFilterValue, logGroups]
  )
  const logGroupsWithOther = useMemo(
    () =>
      logGroups
        .filter(
          (logGroup) =>
            logGroup.logErrorStatus !== LogErrorStatus.ERROR &&
            logGroup.logErrorStatus !== LogErrorStatus.OK &&
            [FilterTypes.ALL, logGroup.serviceName].includes(currentServiceFilterValue)
        )
        .sort(sortByErrorTsDesc),
    [currentServiceFilterValue, logGroups]
  )
  return (
    <LogGroupDashboard
      serviceNames={svcNames}
      logGroupsWithError={logGroupsWithError}
      logGroupsWithOk={logGroupsWithOk}
      logGroupsWithOther={logGroupsWithOther}
      statusFilter={currentStatusFilterValue}
      serviceFilter={currentServiceFilterValue}
      updateStatusFilter={setCurrentStatusFilterValue}
      updateServiceFilter={setCurrentServiceFilterValue}
    />
  )
}

export default LogGroupsPage
