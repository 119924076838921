import { useCallback, useEffect } from 'react'
import { useAuth } from './config/auth'

import './App.css'
import BffWebSocket from './services/websocket'
import { refreshDashboardData } from './services/bffApi/initData'
import { logger } from './services/logger'
import tw from 'tailwind-styled-components'
import { useObsStore } from './store'
import { Router } from './components/routing/Router'
import { BrowserRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { NotificationsDrawer } from './components/notifications/NotificationsDrawer'

const listenToOnlineStatus = (e: any) => {
  logger.info('Browser is online', e)
  BffWebSocket.initialize()
}
const listenToOfflineStatus = (e: any) => {
  logger.info('Browser is offline', e)
  BffWebSocket.close()
}

const ThemeContainer = tw.div<{ $darkToggle: boolean }>`${(p: any) => (p.$darkToggle ? 'dark' : '')} h-full`

function App() {
  const [darkMode, hydrated] = useObsStore(useCallback((state) => [state.darkMode, state._hasHydrated], []))
  const { loggedIn } = useAuth()

  useEffect(() => {
    if (hydrated && loggedIn) {
      logger.info('User authenticated and store hydrated. Initializing the data', hydrated, loggedIn)
      const { lastDataTS } = useObsStore.getState()
      refreshDashboardData(lastDataTS).then(() => BffWebSocket.initialize())
    }
  }, [hydrated, loggedIn])

  useEffect(() => {
    window.addEventListener('online', listenToOnlineStatus)
    window.addEventListener('offline', listenToOfflineStatus)

    return () => {
      window.removeEventListener('online', listenToOnlineStatus)
      window.removeEventListener('offline', listenToOfflineStatus)
    }
  }, [])

  return (
    <BrowserRouter>
      <ThemeContainer $darkToggle={darkMode}>
        <Toaster position='top-center' />
        <div className='app h-full'>
          <NotificationsDrawer />
          <Router />
        </div>
      </ThemeContainer>
    </BrowserRouter>
  )
}

export default App
