import tw from 'tailwind-styled-components'
import { UseFormRegister } from 'react-hook-form'
import React from 'react'

export interface ICheckboxProps {
  id: string
  name: string
  checked: boolean
  label?: string
  valueUpdated: (value: boolean) => void
}

export interface IUncontrolledCheckboxProps {
  id: string
  name: string
  register: UseFormRegister<any>
  label?: string
  required?: boolean
}

export const Checkbox: React.FC<ICheckboxProps> = ({ id, name, checked, valueUpdated, label }) => {
  return (
    <div className='flex items-center mb-4'>
      <TWCheckbox
        id={id}
        name={name}
        type='checkbox'
        checked={checked}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => valueUpdated(Boolean(event.target.value))}
      />
      {label && <TWCheckboxLabel htmlFor={id}>{label}</TWCheckboxLabel>}
    </div>
  )
}

export const UncontrolledCheckbox: React.FC<IUncontrolledCheckboxProps> = ({ id, name, label, required, register }) => {
  return (
    <div className='flex items-center mb-4'>
      <TWCheckbox id={id} type='checkbox' {...register(name, { required })} />
      {label && <TWCheckboxLabel htmlFor={id}>{label}</TWCheckboxLabel>}
    </div>
  )
}

const TWCheckboxLabel = tw.label`
    ml-2 
    text-sm 
    font-medium 
    text-gray-900 
    dark:text-gray-300
`

const TWCheckbox = tw.input`
    w-4 
    h-4 
    text-red-700 
    bg-gray-100 
    rounded 
    border-gray-300 
    focus:ring-blue-500  
    focus:ring-2 
`
