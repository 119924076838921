import { HealthStatus, ILogGroup, LogErrorStatus } from '../../interfaces'
import { LogEventsList } from '../../components/logs/LogEventsList'
import { Button } from '../../components/common/Button'
import { Divider } from '../../components/common/Divider'
import { Description, IDescriptionItem } from '../../components/common/Description'
import { getLocaleTimestamp, getStatusColor } from '../../helpers/statusCardHelpers'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import tw from 'tailwind-styled-components'
import { Statistic } from '../../components/common/Statistic'
import { NoData } from '../../components/common/NoData'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import { shortenLogsName } from '../../utils'

export interface ILogGroupProps {
  logGroup: ILogGroup | undefined
  openModal: () => void
  loading: boolean
}

export const LogGroup: React.FC<ILogGroupProps> = ({ logGroup, openModal, loading }) => {
  const logGroupShortName = shortenLogsName(logGroup?.logGroupName)
  const descriptions = logGroup ? getDescriptions(logGroup) : []

  return (
    <div className='pt-10 px-10 h-screen-no-scroll text-left'>
      {!logGroup && <NoData />}
      {logGroup && (
        <>
          <div className='h-auto'>
            <div className='flex flex-col sm:flex-row justify-between align-center'>
              <h2 className='flex flex-inline items-center text-left mb-4 text-2xl font-bold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white'>
                <Link to='/loggroups'>
                  <ArrowLeftIcon className='w-8 h-8 mr-2 cursor-pointer' />
                </Link>
                {logGroupShortName}
              </h2>

              {logGroup.logErrorStatus === LogErrorStatus.ERROR && (
                <Button clickHandler={openModal} label='Clear Log Error Status' disabled={loading} />
              )}
            </div>

            <div className='flex flex-col items-center md:items-start'>
              <div className='w-fit'>
                <Description items={descriptions} />
              </div>
            </div>
          </div>

          <div className='h-2/3'>
            <div className='flex flex-col mt-6 w-full'>
              <Headline>Log Events (by default last 3 days)</Headline>
              <Divider />
            </div>

            <div className='w-full'>
              <LogEventsList logGroupId={logGroup.logGroupId} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const getDescriptions = (logGroup: ILogGroup): IDescriptionItem[] => {
  const currStatus = logGroup.logErrorStatus ? logGroup.logErrorStatus : HealthStatus.UNKNOWN
  const statusColor = getStatusColor(currStatus)
  const timestamp = getLocaleTimestamp(logGroup.lastUpdateTS, true)
  const lastErrorTimestamp = logGroup.lastLogEventsWithErrorsTS
    ? getLocaleTimestamp(logGroup.lastLogEventsWithErrorsTS)
    : 'Unknown'

  return [
    {
      label: 'Full Name',
      value: (
        <div className='flex flex-col'>
          <span>{logGroup.logGroupName}</span>
          <a href={logGroup.logGroupLink} target='_blank' rel='noreferrer' className='flex items-center'>
            Open in AWS <ArrowTopRightOnSquareIcon className='ml-2 text-gray-900 dark:text-gray-200 w-5 h-5' />
          </a>
        </div>
      )
    },
    {
      label: 'Service Name',
      value: logGroup.serviceName
    },
    {
      label: 'Status',
      value: <Statistic color={statusColor} value={currStatus} />
    },
    {
      label: 'Last Log Update',
      value: timestamp
    },
    {
      label: 'Last Error Occurrence',
      value: lastErrorTimestamp
    },
    {
      label: 'AWS Details',
      value: `${logGroup.awsAccount} : ${logGroup.awsRegion}`
    }
  ]
}

const Headline = tw.h4`
  text-left 
  mb-4 
  text-lg 
  font-bold 
  tracking-tight 
  leading-none 
  text-gray-900 
  md:text-xl 
  lg:text-2xl 
  dark:text-white
`
