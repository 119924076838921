import tw from 'tailwind-styled-components'
import React from 'react'
export interface IDescriptionItem {
  label: string
  value: string | JSX.Element
}

export interface IDescriptionProps {
  items: Array<IDescriptionItem>
}

export const Description: React.FC<IDescriptionProps> = ({ items }) => {
  return (
    <DescriptionContainer>
      <tbody>
        {items.map((item) => (
          <DescriptionItem key={item.label}>
            <DescriptionLabel scope='row'>{item.label}</DescriptionLabel>
            <DescriptionValue>{item.value}</DescriptionValue>
          </DescriptionItem>
        ))}
      </tbody>
    </DescriptionContainer>
  )
}

const DescriptionContainer = tw.table`
    w-full 
    text-sm
    text-left 
    text-gray-500 
    dark:text-gray-300 
    dark:bg-transparent
`

const DescriptionItem = tw.tr`
  bg-white 
  border
  border-b 
  dark:bg-transparent 
  dark:border-gray-300
`

const DescriptionLabel = tw.th`
  py-4 
  px-6 
  font-medium 
  text-gray-900 
  whitespace-nowrap 
  dark:text-white
`

const DescriptionValue = tw.td`
  py-4
  px-6
`
