import { useCallback } from 'react'
import tw from 'tailwind-styled-components'
import { useObsStore } from '../../../store'
import { Divider } from '../../common/Divider'
import { NotificationItem } from '../NotificationItem'
import { XMarkIcon } from '@heroicons/react/20/solid'

export interface INotificationsDrawerProps {}

export const NotificationsDrawer: React.FC<INotificationsDrawerProps> = () => {
  const { notificationsOpen, notifications, toggleNotifications, clearNotifications } = useObsStore(
    useCallback(
      (state) => ({
        notificationsOpen: state.notificationsOpen,
        notifications: state.notifications,
        toggleNotifications: state.toggleNotifications,
        clearNotifications: state.clearNotifications
      }),
      []
    )
  )

  return (
    <DrawerContainer $open={notificationsOpen}>
      <DrawerArea $open={notificationsOpen}>
        <Drawer>
          <header className='px-4 pt-4 dark:text-white w-full flex justify-between'>
            <p className='text-lg font-bold'>Notifications</p>
            {Array.isArray(notifications) && notifications.length > 0 && (
              <button
                type='button'
                aria-label='clear notifications'
                className='cursor-pointer rounded-lg px-3 dark:bg-white dark:text-black bg-gray-800 text-white'
                onClick={clearNotifications}
              >
                Clear
              </button>
            )}
            <button type='button' aria-label='close icon' className='cursor-pointer' onClick={toggleNotifications}>
              <XMarkIcon className='w-6 h-6' />
            </button>
          </header>
          <Divider />
          {notifications.map((notification) => (
            <NotificationItem notification={notification} key={notification.id} />
          ))}
        </Drawer>
      </DrawerArea>
      <section className='w-screen h-full cursor-pointer' onClick={toggleNotifications}></section>
    </DrawerContainer>
  )
}

const DrawerContainer = tw.main<{ $open: boolean }>`
  ${(p: any) =>
    p.$open
      ? 'transition-opacity opacity-100 duration-500 translate-x-0'
      : 'transition-all delay-100 opacity-0 translate-x-full'}
  fixed 
  overflow-hidden 
  z-10 
  bg-black 
  bg-opacity-25 
  inset-0 
  transform 
  ease-in-out
`

const DrawerArea = tw.section<{ $open: boolean }>`
${(p: any) => (p.$open ? 'translate-x-0' : 'translate-x-full')}
  w-screen 
  max-w-md
  right-0 
  absolute 
  bg-white 
  rounded-md
  h-full 
  shadow-xl 
  delay-400 
  duration-500 
  ease-in-out 
  transition-all 
  transform
`

const Drawer = tw.article`
  relative 
  w-screen 
  rounded-md
  max-w-md 
  pb-10 
  flex 
  flex-col 
  items-center
  space-y-6 
  overflow-y-scroll 
  h-full 
  dark:bg-gray-700
  bg-gray-100
`
