import { Button } from '../../common/Button'
import { Description, IDescriptionItem } from '../../common/Description'
import { LogGroupList } from '../../logs/LogGroupList'
import { ConfirmationModal } from '../../ConfirmationModal'
import { HealthStatus, ILogGroup, IService } from '../../../interfaces'
import API from '../../../services/bffApi'
import { useCallback, useState } from 'react'
import { getLocaleTimestamp, getStatusColor } from '../../../helpers/statusCardHelpers'
import { Divider } from '../../common/Divider'
import { Statistic } from '../../common/Statistic'
import { executeWithToast } from '../../../helpers/executionUtils'

export interface IServiceDetailsProps {
  service: IService
  logGroups: ILogGroup[]
}

const toastConfig = {
  loadingMessage: 'Clearing log status',
  successMessage: 'Cleared service log status',
  failureMessage: 'Could not clear the service log status'
}

export const ServiceDetails: React.FC<IServiceDetailsProps> = ({ service, logGroups }) => {
  const serviceName = service?.serviceName ?? ''

  const [loading, setLoading] = useState<boolean>(false)
  const [confirmClearModalOpen, setConfirmClearModalOpen] = useState<boolean>(false)
  const openModal = useCallback(() => setConfirmClearModalOpen(true), [setConfirmClearModalOpen])
  const closeModal = useCallback(() => setConfirmClearModalOpen(false), [setConfirmClearModalOpen])

  const descriptions = service ? getDescriptions(service) : []

  const clearLogStatus = useCallback(() => {
    closeModal()
    setLoading(true)
    const clear = async () => await executeWithToast(() => API.clearServiceLogErrorStatus(serviceName), toastConfig)
    clear().finally(() => setLoading(false))
  }, [closeModal, serviceName])

  return (
    <>
      <div className='flex flex-col sm:flex-row justify-end align-center'>
        <Button clickHandler={openModal} label='Clear Log Error Status' disabled={loading} />
      </div>

      <Divider />

      <div className='flex flex-col lg:flex-row justify-between'>
        <div className='pb-5'>
          <Description items={descriptions} />
        </div>

        <div className='w-full lg:pl-6 text-left'>
          <LogGroupList logGroups={logGroups} />
        </div>
      </div>

      <ConfirmationModal
        modalOpen={confirmClearModalOpen}
        closeModal={closeModal}
        heading='Are you sure you want to clear the log error status?'
        message='Please note that this will clear all log groups under this service.'
        confirm={{ label: 'Clear', action: clearLogStatus }}
        cancel={{ label: 'Cancel', action: () => setConfirmClearModalOpen(false) }}
      />
    </>
  )
}

const getDescriptions = (service: IService): IDescriptionItem[] => {
  const healthTimestamp = service.lastHealthTS ? getLocaleTimestamp(service.lastHealthTS, true) : 'Unknown'
  const logTimestamp = service.lastLogGroupTS ? getLocaleTimestamp(service.lastLogGroupTS, true) : 'Unknown'

  const currLogStatus = service.logErrorStatus == null ? HealthStatus.UNKNOWN : service.logErrorStatus
  const currHealthStatus = service.healthStatus?.status
  const healthStatusColor = getStatusColor(currHealthStatus)
  const logStatusColor = getStatusColor(currLogStatus)

  const descriptions = [
    {
      label: 'Service Name',
      value: service.serviceName
    },
    {
      label: 'Health Status',
      value: <Statistic color={healthStatusColor} value={currHealthStatus} />
    },
    {
      label: 'Last Health Update',
      value: healthTimestamp
    },
    {
      label: 'Log Status',
      value: <Statistic color={logStatusColor} value={currLogStatus} />
    },
    {
      label: 'Last Log Update',
      value: logTimestamp
    }
  ]

  if (service.contactInfo) {
    descriptions.push({
      label: 'Contact',
      value: (
        <div className='flex flex-col'>
          <span>{service.contactInfo.name}</span>
          <a href={'mailto:' + service.contactInfo.email}>{service.contactInfo.email}</a>
        </div>
      )
    })
  }

  if (service.team) {
    descriptions.push({
      label: 'Team',
      value: service.team
    })
  }

  return descriptions
}
