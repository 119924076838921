import { DashboardFilters, FilterTypes } from '../../../helpers/statusCardHelpers'
import { LogErrorStatus } from '../../../interfaces'
import { Select } from '../../common/Select'

export interface IStatusFilterProps {
  value: DashboardFilters
  valueUpdated: (filter: string) => void
}

export const StatusFilter: React.FC<IStatusFilterProps> = ({ value, valueUpdated }) => {
  return (
    <Select id='statusFilter' name='statusFilter' value={value} valueUpdated={valueUpdated} label='Filter by Status'>
      <option value={FilterTypes.ALL}>All</option>
      <option value={LogErrorStatus.ERROR}>{LogErrorStatus.ERROR}</option>
      <option value={LogErrorStatus.OK}>{LogErrorStatus.OK}</option>
      <option value={FilterTypes.OTHER}>Other</option>
    </Select>
  )
}
