import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../config/auth'
import bffApi from '../../services/bffApi'
import { LoginForm } from '../../components/login/LoginForm'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { logger } from '../../services/logger'
import { InlinedNotification } from '../../components/InlinedNotification'
import { STORAGE_PREVIOUS_PAGE_KEY } from '../../config/constants'

export type LoginState = 'EMAIL_INPUT' | 'CHALLENGE_INPUT' | 'SIGNED_IN'

const SignIn = () => {
  const { state, search } = useLocation()
  const code = new URLSearchParams(search).get('code')

  const [loginState, setLoginState] = useState<LoginState>('EMAIL_INPUT')
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<string>('')
  const [useEmailLink, setUseEmailLink] = useState<boolean>(false)
  const [passcode, setPasscode] = useState<string>('')
  const [authError, setAuthError] = useState<string | null>('')
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)
  const { signIn, verifyCode } = useAuth()
  const navigate = useNavigate()

  const startLogin = useCallback(async () => {
    if (!email || !email.length) {
      setAuthError('Invalid user email.')
      return
    }

    setLoading(true)
    try {
      if (useEmailLink) {
        await bffApi.loginWithPasswordless(email)
      }

      logger.debug('Signing in', email)

      const user = await signIn(email)
      if (user) {
        setCognitoUser(user)
        setLoginState('CHALLENGE_INPUT')
        if (state) {
          logger.debug('Preserving previous page', state)
          localStorage.setItem(STORAGE_PREVIOUS_PAGE_KEY, JSON.stringify(state))
        }
      } else {
        setAuthError('Invalid user email.')
      }
    } catch (error) {
      logger.error('Sign in error', error)
      setAuthError((error as Error).message)
    } finally {
      setLoading(false)
    }
  }, [state, email, signIn, useEmailLink])

  const verifyLogin = useCallback(async () => {
    if (!code?.length && !passcode?.length) {
      setAuthError('Invalid passcode.')
      return
    }

    setLoading(true)
    try {
      logger.debug('Trying to log in')

      const result = await verifyCode(cognitoUser, code ?? passcode)
      if (!result) {
        setAuthError('Invalid passcode.')
        setLoading(false)
      }
    } catch (error) {
      logger.error('Verify error', error)
      setAuthError((error as Error).message)
      setLoading(false)
    }
  }, [code, cognitoUser, passcode, verifyCode])

  const onSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    setAuthError(null)
    if (loginState === 'EMAIL_INPUT') {
      await startLogin()
    } else if (loginState === 'CHALLENGE_INPUT') {
      await verifyLogin()
    }
  }

  useEffect(() => {
    if (code && code.length > 0) {
      const previousPage = localStorage.getItem(STORAGE_PREVIOUS_PAGE_KEY)
      const redirectLocation = ((previousPage ? JSON.parse(previousPage) : state) as Location) ?? { pathname: '/' }
      logger.debug('Redirect location', redirectLocation)

      setPasscode(code)
      setLoginState('CHALLENGE_INPUT')
      verifyLogin().then(() => navigate(redirectLocation))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDismiss = useCallback(() => setAuthError(null), [])
  const onCancel = useCallback(() => {
    setPasscode('')
    setEmail('')
    setLoginState('EMAIL_INPUT')
  }, [])
  const togglePasswordless = useCallback(() => setUseEmailLink((previousValue) => !previousValue), [])

  return (
    <div className='flex flex-col h-screen-no-scroll items-center justify-center'>
      <InlinedNotification error={authError} dismiss={onDismiss} />
      <div className='flex items-center justify-center'>
        <LoginForm
          loginState={loginState}
          loading={loading}
          usePasswordless={useEmailLink}
          updatePaswordless={togglePasswordless}
          email={email}
          updateEmail={setEmail}
          passcode={passcode}
          updatePasscode={setPasscode}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  )
}

export default SignIn
