import React, { CSSProperties, ReactNode, PropsWithChildren } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import tw from 'tailwind-styled-components'

export interface ICardProps extends PropsWithChildren {
  collapsible?: boolean
  IconLeft?: ReactNode
  open: boolean
  title: string
  color: string
  style?: CSSProperties
}

export const Card: React.FC<ICardProps> = ({ collapsible, open, title, color, children, IconLeft, style }) => {
  return (
    <div style={style}>
      <Disclosure defaultOpen={open}>
        {({ open }) => (
          <>
            <CollapseButton disabled={!collapsible} $color={color}>
              {IconLeft}
              <span className='w-full'>{title}</span>
              {collapsible && (
                <ChevronUpIcon className={`ml-2 ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-200`} />
              )}
            </CollapseButton>
            <Disclosure.Panel className='pb-2 text-sm text-gray-500'>{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

const CollapseButton = tw(Disclosure.Button)<{ $color: string }>`
  ${(p: any) => p.$color}
  flex 
  w-full 
  justify-between
  items-center
  min-height: 3rem
  px-4 
  py-2 
  text-center  
  text-white
  text-xl
  focus:outline-none 
  focus-visible:ring 
  focus-visible:ring-gray-500 
  focus-visible:ring-opacity-75
`
