import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { Hub } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import BffWebSocket from '../services/websocket'
import { logger } from '../services/logger'
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import { STORAGE_ID_TOKEN_KEY, STORAGE_PREVIOUS_PAGE_KEY, STORAGE_SIGNIN_KEY } from './constants'
import { useObsStore } from '../store'

interface ICognitoUserSession {
  userAuthToken: string
}
type AC = {
  loggedIn: boolean
  isAuthenticated: () => Promise<boolean>
  signIn: (email: string) => Promise<any>
  verifyCode: (cognitoUser: any, code: string) => Promise<boolean>
  signOut: typeof Auth.signOut
  currentSession: () => Promise<ICognitoUserSession | null>
}

const AuthContext = createContext<AC>({
  loggedIn: false,
  isAuthenticated: () => Promise.resolve(false),
  signIn: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(false),
  signOut: () => Promise.resolve(),
  currentSession: () => Promise.resolve(null)
})

export const APIContext = createContext<string | null>(null)

interface IAuthProviderProps {
  children: ReactNode
}

const AuthProvider = (props: IAuthProviderProps) => {
  const [loggedIn, setLoggedIn] = useState<AC['loggedIn']>(false)

  const signOut = useCallback(async () => {
    logger.debug('signOut call')
    await Auth.signOut()
    setLoggedIn(false)
    BffWebSocket.close()

    for (let item in localStorage) {
      if (item.match(/CognitoIdentityServiceProvider|dashboard|amplify/)) {
        logger.debug('Deleting item', item)
        localStorage.removeItem(item)
      }
    }
  }, [])

  const currentSession = useCallback(async () => {
    logger.debug('currentSession call')

    try {
      const session = await Auth.currentSession()

      if (session.isValid()) {
        setLoggedIn(true)

        // notify the app that the user is authenticated
        Hub.dispatch('app', { event: 'authenticated' }, 'auth')

        return { userAuthToken: session.getAccessToken()?.getJwtToken() }
      }
    } catch (err) {
      signOut()
      if (
        !(err instanceof Error && err.name === 'NotAuthorizedException') &&
        !(err as string).match(/No current user/)
      ) {
        throw err
      }
    }
    return null
  }, [signOut])

  const isAuthenticated = useCallback(async () => {
    logger.debug('isAuthenticated call')
    try {
      await currentSession()
      return true
    } catch (error) {
      return false
    }
  }, [currentSession])

  const signIn = async (email: string): Promise<any> => {
    const user = await Auth.signIn(email)

    // Store login intermediate state to support "magic link" functionality.
    localStorage.setItem(STORAGE_SIGNIN_KEY, JSON.stringify(user))

    return user
  }

  const verifyCode = async (user: CognitoUser, code: string): Promise<boolean> => {
    logger.debug('verifyCode call', user, code)
    let verifyCognitoUser: any = user
    try {
      if (!verifyCognitoUser) {
        logger.info('Logging in')
        const storedUser = JSON.parse(localStorage.getItem(STORAGE_SIGNIN_KEY)!)
        logger.info('Got user from state', storedUser)
        verifyCognitoUser = new CognitoUser({
          Username: storedUser.username,
          Pool: new CognitoUserPool({
            UserPoolId: storedUser.pool.userPoolId,
            ClientId: storedUser.pool.clientId
          })
        })
        verifyCognitoUser!.Session = storedUser.Session
        verifyCognitoUser!.username = storedUser.username
      }

      const user = await Auth.sendCustomChallengeAnswer(verifyCognitoUser, code)
      const isOkAuth = await isAuthenticated()
      if (isOkAuth) {
        const session = await Auth.currentSession()
        const idToken = session.getIdToken().getJwtToken()

        const { user: obsUser, clear } = useObsStore.getState()
        logger.info('Comparing', user.username, obsUser?.email)
        if (user.username !== obsUser?.email) {
          logger.info('Changed user, clearing state')
          clear()
        }

        setLoggedIn(true)
        localStorage.setItem(STORAGE_ID_TOKEN_KEY, idToken)
        localStorage.removeItem(STORAGE_SIGNIN_KEY)
        localStorage.removeItem(STORAGE_PREVIOUS_PAGE_KEY)
      }
      return isOkAuth
    } catch (err) {
      logger.error('verifyCode error', err)
      return false
    }
  }

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        isAuthenticated,
        signIn,
        verifyCode,
        signOut,
        currentSession
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
