import { useLocation, useNavigate } from 'react-router-dom'
import { useSearchParams } from '../../../hooks'
import { ILogGroup, LogErrorStatus } from '../../../interfaces'
import { LogsDashboardCard } from '../../../components/logs/LogsDashboardCard'
import { DashboardFilters, FilterTypes } from '../../../helpers/statusCardHelpers'
import tw from 'tailwind-styled-components'
import { Select } from '../../../components/common/Select'
import React, { useCallback } from 'react'
import { StatusFilter } from '../../../components/filters/StatusFilter'

export interface ILogGroupDashboardProps {
  serviceNames: string[]
  logGroupsWithError: ILogGroup[]
  logGroupsWithOk: ILogGroup[]
  logGroupsWithOther: ILogGroup[]
  statusFilter: DashboardFilters
  serviceFilter: string
  updateStatusFilter: (filter: DashboardFilters) => void
  updateServiceFilter: (filter: string) => void
}

const LogGroupDashboard: React.FC<ILogGroupDashboardProps> = ({
  serviceNames,
  logGroupsWithOk,
  logGroupsWithError,
  logGroupsWithOther,
  statusFilter,
  serviceFilter,
  updateStatusFilter,
  updateServiceFilter
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = useSearchParams()

  const changeStatusFilter = useCallback(
    (value: string) => {
      updateStatusFilter(value as FilterTypes)
      searchParams.set('status', value)
      navigate({ ...location, search: searchParams.toString() })
    },
    [location, navigate, searchParams, updateStatusFilter]
  )

  const changeServiceFilter = useCallback(
    (value: string) => {
      updateServiceFilter(value)
      searchParams.set('service', value)
      navigate({ ...location, search: searchParams.toString() })
    },
    [location, navigate, searchParams, updateServiceFilter]
  )

  return (
    <div className='pt-10 px-10 h-screen-no-scroll'>
      <h2 className='text-left mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white'>
        Log Groups
      </h2>
      <div className='flex flex-col md:flex-row gap-4'>
        <div className='flex-col w-48 text-left'>
          <StatusFilter value={statusFilter} valueUpdated={changeStatusFilter} />
        </div>
        <div className='flex-col w-48 text-left'>
          <Select
            id='servicesFilter'
            name='servicesFilter'
            value={serviceFilter}
            valueUpdated={changeServiceFilter}
            label='Filter by Service'
          >
            <option value={FilterTypes.ALL}>All</option>
            {serviceNames.map((svc) => (
              <option value={svc} key={svc}>
                {svc}
              </option>
            ))}
          </Select>
        </div>
      </div>
      {(statusFilter === FilterTypes.ALL || statusFilter === LogErrorStatus.ERROR) && (
        <div className='pt-4'>
          <LogsGridHeader>ERROR</LogsGridHeader>
          <LogsGrid>
            {logGroupsWithError.length > 0 ? (
              logGroupsWithError.map((logGroup) => (
                <LogsDashboardCard key={logGroup.logGroupId} logGroup={logGroup} open />
              ))
            ) : (
              <p className='dark:text-white'>No Log Groups in ERROR</p>
            )}
          </LogsGrid>
        </div>
      )}
      {(statusFilter === FilterTypes.ALL || statusFilter === LogErrorStatus.OK) && (
        <div className='pt-4'>
          <LogsGridHeader>OK</LogsGridHeader>
          <LogsGrid>
            {logGroupsWithOk.length > 0 ? (
              logGroupsWithOk.map((logGroup) => <LogsDashboardCard key={logGroup.logGroupId} logGroup={logGroup} />)
            ) : (
              <p className='dark:text-white'>No Log Groups in OK</p>
            )}
          </LogsGrid>
        </div>
      )}
      {(statusFilter === FilterTypes.ALL || statusFilter === FilterTypes.OTHER) && (
        <div className='pt-4'>
          <LogsGridHeader>Other Statuses</LogsGridHeader>
          <LogsGrid>
            {logGroupsWithOther.length > 0 ? (
              logGroupsWithOther.map((logGroup) => <LogsDashboardCard key={logGroup.logGroupId} logGroup={logGroup} />)
            ) : (
              <p className='dark:text-white'>No Other Log Group Statuses</p>
            )}
          </LogsGrid>
        </div>
      )}
    </div>
  )
}

const LogsGridHeader = tw.h3`
  text-left 
  text-2xl 
  font-bold 
  dark:text-white
`

const LogsGrid = tw.div`
  grid 
  grid-cols-1 
  sm:grid-cols-2 
  lg:grid-cols-3 
  xl:grid-cols-4 
  gap-4 
  justify-items-center 
  border-t-2 
  pt-4
`

export default LogGroupDashboard
