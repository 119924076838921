import { HealthStatus, LogErrorStatus } from '../../interfaces'
import tw from 'tailwind-styled-components'

export interface IStatusIndicatorProps {
  status: LogErrorStatus | HealthStatus
}

const getStatusColor = (status: LogErrorStatus | HealthStatus): string => {
  switch (status) {
    case 'OK':
    case 'UP':
      return 'bg-lime-700'
    case 'ERROR':
    case 'DOWN':
      return 'bg-red-700'
    case 'OUT_OF_SERVICE':
      return 'bg-amber-600'
    case 'UNKNOWN':
    default:
      return 'bg-gray-900'
  }
}

export const StatusIndicator: React.FC<IStatusIndicatorProps> = ({ status }) => {
  const statusColor = getStatusColor(status)
  const statusText = status.replaceAll('_', ' ')
  return (
    <TWSpan $color={statusColor}>
      <b>{statusText}</b>
    </TWSpan>
  )
}

const TWSpan = tw.span<{ $color: string }>`
  ${(p: any) => p.$color}
  text-center
  text-white 
  self-center 
  rounded-lg 
  mr-3 
  w-14 
  mb-1 
  py-1 
  px-2
`
