import axios, { AxiosError, CancelTokenSource } from 'axios'
import axiosRetry, { exponentialDelay } from 'axios-retry'
import {
  IAppVersion,
  ILogEventsGroup,
  ILogGroup,
  IService,
  IServiceHealth,
  IUpdateServiceRequest,
  IPromoteRelease,
  IUser
} from '../../interfaces'
import { Auth } from '@aws-amplify/auth'
import { STORAGE_ID_TOKEN_KEY } from '../../config/constants'
import { logger } from '../logger'

class BffApiService {
  private readonly baseUrl: string | undefined
  private idToken: string | null

  constructor() {
    axiosRetry(axios, { retries: 3, retryDelay: this.retryFunction })
    axios.interceptors.response.use(
      (request) => request,
      async (error) => await this.handleError(error)
    )
    this.baseUrl = process.env.REACT_APP_API_BASE_URL
    this.idToken = localStorage.getItem(STORAGE_ID_TOKEN_KEY)
  }

  public async getServiceData(since?: string): Promise<{ services: IService[] } | null> {
    const sinceParam = since ? `?since=${since}` : ''
    const url = `${this.baseUrl}/services${sinceParam}`
    const response = await axios.request({
      method: 'GET',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    return response?.data
  }

  public async updateService(serviceName: string, data: IUpdateServiceRequest): Promise<void> {
    const url = `${this.baseUrl}/services/${serviceName}`
    const response = await axios.request({
      method: 'PUT',
      data,
      url,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    return response?.data
  }

  public async getServiceReleases(serviceName: string): Promise<IAppVersion[]> {
    const svcName = encodeURIComponent(serviceName)
    const url = `${this.baseUrl}/services/${svcName}/release`
    return await this.executeGet(url)
  }

  public async postRelease(serviceName: string, body: IPromoteRelease): Promise<IAppVersion[]> {
    const svcName = encodeURIComponent(serviceName)
    return await axios.post(`${this.baseUrl}/services/${svcName}/promote`, body, {
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
  }

  private async executeGet(url: string) {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    return response?.data
  }

  public async clearServiceLogErrorStatus(serviceName: string): Promise<IService | null> {
    const svcName = encodeURIComponent(serviceName)
    const url = `${this.baseUrl}/services/${svcName}/clearlogstatus`
    const response = await axios.request({
      method: 'PATCH',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    const resObj = response?.data as { service: IService }
    return resObj.service
  }

  public async patchUser(showServices: string[]) {
    const url = `${this.baseUrl}/user`
    await axios.request({
      method: 'PATCH',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` },
      data: { showServices }
    })
  }

  public async getLogGroupData(serviceName: string, since?: string): Promise<{ logGroups: ILogGroup[] } | null> {
    const svcName = encodeURIComponent(serviceName)
    const sinceParam = since ? `?since=${since}` : ''
    const url = `${this.baseUrl}/loggroups/${svcName}${sinceParam}`
    const response = await axios.request({
      method: 'GET',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    return response?.data
  }

  public async clearLogGroupLogErrorStatus(logGroupId: string): Promise<ILogGroup | null> {
    const lgID = encodeURIComponent(logGroupId)
    const url = `${this.baseUrl}/loggroups/${lgID}/clearlogstatus`
    const response = await axios.request({
      method: 'PATCH',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    const resObj = response?.data as { logGroup: ILogGroup }
    return resObj.logGroup
  }

  public async getLogEventsGroupData(
    logGroupId: string,
    from: string,
    to?: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<{ logEvents: ILogEventsGroup[] } | null> {
    const lgID = encodeURIComponent(logGroupId)
    const url = `${this.baseUrl}/logs/${lgID}?from=${from}${to ? '&to=' + to : ''}`
    const response = await axios.request({
      method: 'GET',
      url,
      cancelToken: cancelTokenSource?.token,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    return response?.data
  }

  public async getServiceHealth(
    serviceName: string,
    since?: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<{ serviceHealth: IServiceHealth[] }> {
    const svcName = encodeURIComponent(serviceName)
    const sinceParam = since ? `?since=${since}` : ''

    const url = `${this.baseUrl}/services/${svcName}/health${sinceParam}`
    const response = await axios.request<{ serviceHealth: IServiceHealth[] }>({
      method: 'GET',
      url,
      cancelToken: cancelTokenSource?.token,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    return response?.data ?? []
  }

  public async getUser(): Promise<IUser> {
    const url = `${this.baseUrl}/user`
    const response = await axios.request({
      method: 'GET',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    return response?.data.user
  }

  public async loginWithPasswordless(email: string): Promise<void> {
    const url = `${this.baseUrl}/user/otp/passwordless`
    await axios.request({
      method: 'POST',
      url,
      headers: { 'x-client-id': `${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}` },
      data: { email }
    })
  }

  public async setupOtp(): Promise<string> {
    const url = `${this.baseUrl}/user/otp`
    const response = await axios.request({
      method: 'POST',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
    return response?.data.otpAuth
  }

  public async confirmOtp(otpToken: string): Promise<void> {
    const url = `${this.baseUrl}/user/otp`
    await axios.request({
      method: 'PATCH',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` },
      data: { otpToken }
    })
  }

  public async cancelOtpSetup(): Promise<void> {
    const url = `${this.baseUrl}/user/otp/setup`
    await axios.request({
      method: 'DELETE',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
  }

  public async deleteOtp(): Promise<void> {
    const url = `${this.baseUrl}/user/otp`
    await axios.request({
      method: 'DELETE',
      url,
      headers: { Authorization: `Bearer ${this.idToken}` }
    })
  }

  public async refreshToken() {
    try {
      logger.info('Refreshing the token')
      const session = await Auth.currentSession()
      const idToken = session.getIdToken().getJwtToken()

      localStorage.setItem(STORAGE_ID_TOKEN_KEY, idToken)
      this.idToken = idToken

      return idToken
    } catch (error: any) {
      logger.error('Could not refresh the tokens', error)
      return undefined
    }
  }

  private async handleError(error: any) {
    const originalConfig = error.config
    if (error?.response?.status === 401 && !originalConfig._retry) {
      const apiToken = await this.refreshToken()
      const newConfig = {
        ...originalConfig,
        headers: { ...originalConfig.headers, Authorization: `Bearer ${apiToken}` },
        _retry: true // set a flag so we don't retry indefinitely
      }
      return axios(newConfig)
    }

    return Promise.reject(error)
  }

  private retryFunction(retryCount: number, error: AxiosError) {
    logger.info('Retrying request', {
      retryCount,
      responseStatus: error.response?.status,
      error: error.message
    })
    return exponentialDelay(retryCount)
  }
}

export default new BffApiService()
