import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PrivateOutlet } from '../PrivateOutlet'
import { Header } from '../../common/Header'
import SignIn from '../../../pages/SignIn'
import { ServicePage } from '../../../pages/Dashboard/Services'
import LogGroupsPage from '../../../pages/Dashboard/LogGroups/LogGroupsPage'
import Service from '../../../pages/Service'
import LogGroupPage from '../../../pages/LogGroup/LogGroupPage'
import ProfilePage from '../../../pages/Profile'

export const Router: React.FC = () => {
  return (
    <div>
      <Header />
      <div className='bg-white dark:bg-gray-700 h-full min-h-full overflow-auto'>
        <Routes>
          <Route path='/' element={<Navigate replace to='/services' />} />
          <Route path='/signIn' element={<PrivateOutlet publicPath='/services' />}>
            <Route path='' element={<SignIn />} />
          </Route>
          <Route path='/' element={<PrivateOutlet challengePath='/signIn' />}>
            <Route path='' element={<Navigate replace to='services' />} />
            <Route path='services' element={<ServicePage />}></Route>
            <Route path='loggroups' element={<LogGroupsPage />}></Route>
            <Route path='services/:name' element={<Service />}></Route>
            <Route path='loggroups/:id/*' element={<LogGroupPage />}></Route>
            <Route path='profile' element={<ProfilePage />}></Route>
          </Route>
        </Routes>
      </div>
    </div>
  )
}
