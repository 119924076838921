import { HealthStatus, ILogGroup } from '../../../interfaces'
import { Link } from 'react-router-dom'
import { getStatusColor } from '../../../helpers/statusCardHelpers'
import { Headline } from '../../common/Headline'
import { ColorText } from '../../common/ColorText'
import { StatusIndicator } from '../../StatusIndicator'
import { NoData } from '../../common/NoData'

export interface ILogGroupListItemProps {
  logGroup: ILogGroup
}

export interface ILogGroupListProps {
  logGroups: ILogGroup[]
}

export const LogGroupListItem: React.FC<ILogGroupListItemProps> = ({ logGroup }) => {
  const logStatus = logGroup.logErrorStatus ? logGroup.logErrorStatus : HealthStatus.UNKNOWN
  const logStatusColor = getStatusColor(logStatus)

  const linkTarget = `/loggroups/${encodeURIComponent(logGroup.logGroupId)}`

  return (
    <Link to={linkTarget}>
      <p className='text-md m-0' key={logGroup.logGroupId} color='inherit'>
        <StatusIndicator status={logStatus} />
        <ColorText color={logStatusColor}>{logGroup.logGroupId.replace(/\\/g, '/')}</ColorText>
      </p>
    </Link>
  )
}

export const LogGroupList: React.FC<ILogGroupListProps> = ({ logGroups }) => {
  return (
    <>
      <div className='p-5'>
        <Headline>Log Groups</Headline>
        {logGroups.length === 0 && <NoData text='No log groups' />}
        {logGroups.length > 0 && (
          <ul className='w-full list-none list-inside text-gray-500 dark:text-gray-400'>
            {logGroups.map((logGroup) => (
              <li key={logGroup.logGroupId} className='border-t border-l border-r last:border-b dark:border-white p-4'>
                <LogGroupListItem logGroup={logGroup} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}
