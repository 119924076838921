import { ILogEventsGroup } from '../../../interfaces'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { LogEventGroupItem } from '../LogEventItem'
import API from '../../../services/bffApi'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { sortByUpdateDesc } from '../../../helpers/statusCardHelpers'
import tw from 'tailwind-styled-components'
import { Button } from '../../common/Button'
import { executeWithToast, IExecuteWithToastOptions } from '../../../helpers/executionUtils'
import { NoData } from '../../common/NoData'
import { Virtuoso } from 'react-virtuoso'
import axios from 'axios'

dayjs.extend(utc)

export interface ILogEventsListProps {
  logGroupId: string
}

const toggleConfig: IExecuteWithToastOptions = {
  shouldThrow: true,
  failureMessage: 'Could not load Log events'
}

const subtract3Days = (date: string): string => dayjs.utc(date).subtract(3, 'days').format()
const add3Days = (date: string): string => dayjs.utc(date).add(3, 'days').format()

export const LogEventsList: React.FC<ILogEventsListProps> = ({ logGroupId }) => {
  const [logEvents, setLogEvents] = useState<ILogEventsGroup[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  // default 3 days from now
  const now = dayjs.utc()
  const [from, setFrom] = useState<string>(now.subtract(3, 'days').format())
  const [to, setTo] = useState<string>(now.format())
  const events = useMemo(() => logEvents.sort(sortByUpdateDesc), [logEvents])
  const fromLabel = useMemo(() => new Date(from).toLocaleString(), [from])

  const fetchMore = useCallback(() => {
    setFrom(subtract3Days)
    setTo(subtract3Days)
  }, [])

  const revertPaging = useCallback(() => {
    setFrom(add3Days)
    setTo(add3Days)
  }, [])

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    setLoading(true)
    const fetchLogs = async () => {
      const response = await API.getLogEventsGroupData(logGroupId, from, to, cancelToken)
      if (response) {
        setLogEvents((previousEvents) => [...previousEvents, ...response.logEvents])
      }
    }

    executeWithToast(fetchLogs, toggleConfig)
      .catch(() => revertPaging())
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
    }
  }, [logGroupId, from, to, revertPaging])

  return (
    <div className='flex flex-col items-center'>
      <div className='w-full flex flex-col sm:flex-row justify-between align-center'>
        <LogsHeader>Displaying logs since: {fromLabel}</LogsHeader>
        <Button loading={loading} disabled={loading} label='Load More' primary clickHandler={fetchMore} />
      </div>
      <div className='overscroll-auto w-full overflow-scroll' style={{ maxHeight: '60vh' }}>
        <Virtuoso
          style={{ height: '60vh', maxHeight: '60vh' }}
          components={{ EmptyPlaceholder: EmptyPlaceholder }}
          data={events}
          computeItemKey={(_, item) => item.logStreamName}
          totalCount={events.length}
          itemContent={(_, item) => <LogEventGroupItem logEventGroup={item} />}
        />
      </div>
    </div>
  )
}

const EmptyPlaceholder: React.FC<{ context?: any }> = () => <NoData text='No logs' />

const LogsHeader = tw.h4`
  text-left 
  mb-4 
  text-lg 
  font-semibold 
  tracking-tight 
  leading-none 
  text-gray-900 
  md:text-xl 
  lg:text-2xl 
  dark:text-white    
`
