import produce from 'immer'
import create from 'zustand'
import { persist } from 'zustand/middleware'
import { ChangeType, ILogEventsGroup, ILogGroup, INotification, IService, IUser, IFlags } from '../interfaces'
import localForage from 'localforage'

export interface ServiceChange {
  changeType: ChangeType
  service: IService | { serviceName: string }
}
export interface LogGroupChange {
  changeType: ChangeType
  logGroup: ILogGroup
}

export interface IObsStore {
  _hasHydrated: boolean
  setHasHydrated: (state: boolean) => void
  darkMode: boolean
  bookmarks: boolean
  notificationsOpen: boolean
  notifications: INotification[]
  lastDataTS?: string
  services?: { [key: string]: IService }
  logGroups?: { [key: string]: ILogGroup }
  logEvents?: { [key: string]: ILogEventsGroup }
  user?: IUser
  toggleBookmarks: () => void
  toggleDarkMode: () => void
  toggleNotifications: () => void
  addNotification: (notification: INotification) => void
  removeNotification: (id: string) => void
  clearNotifications: () => void
  updateUser: (user: Partial<IUser>) => void
  appendServices: (services: ServiceChange[], clean?: boolean) => void
  appendLogGroups: (logGroups: LogGroupChange[], clean?: boolean) => void
  clear: () => void,
  featureFlags: IFlags,
  updateFeatureFlags: (flags: IFlags) => void
}

// Initial values are undefined, as a flag that the initial fetch hasn't happened.
// Typical documentation also uses an additional flag (ex. fetching: true), in
// which case, if that intermediate state is needed, add  that field per item.
export const useObsStore = create<IObsStore>()(
  persist(
    (set) => ({
      // internals
      _hasHydrated: false,
      setHasHydrated: (state: boolean) => {
        set({
          _hasHydrated: state
        })
      },
      // end internals

      darkMode: true,
      bookmarks: true,
      notificationsOpen: false,
      lastDataTS: undefined,
      services: undefined,
      logGroups: undefined,
      user: undefined,
      notifications: [],
      featureFlags: { "enable-new-releases": false },
      // logEvents(Group?): undefined,

      toggleBookmarks: () =>
        set(
          produce((draft) => {
            draft.bookmarks = !draft.bookmarks
          }),
          false
        ),
      updateFeatureFlags: (featureFlags: IFlags) =>
        set(
          produce((draft) => {
            draft.featureFlags = featureFlags
          }),
          false
        ),

      toggleDarkMode: () =>
        set(
          produce((draft) => {
            draft.darkMode = !draft.darkMode
          }),
          false
        ),

      toggleNotifications: () =>
        set(
          produce((draft) => {
            draft.notificationsOpen = !draft.notificationsOpen
          }),
          false
        ),

      addNotification: (notification: INotification) =>
        set(
          produce((draft) => {
            draft.notifications.push(notification)
          }),
          false
        ),

      removeNotification: (id: string) =>
        set(
          produce((draft) => {
            draft.notifications = draft.notifications.filter((n: INotification) => n.id !== id)
          }),
          false
        ),

      clearNotifications: () =>
        set(
          produce((draft) => {
            draft.notifications = []
          }),
          false
        ),

      updateUser: (user: Partial<IUser>) =>
        set(
          produce((draft) => {
            draft.user = { ...draft.user, ...user, showServices: user?.showServices || [] }
          }),
          false
        ),

      appendServices: (services: ServiceChange[], clean?: boolean) =>
        set(
          produce((draft) => {
            draft.services = clean ? {} : draft.services || {}
            services.forEach((change) => {
              switch (change.changeType) {
                case ChangeType.CREATE:
                case ChangeType.UPDATE:
                  draft.services[change.service.serviceName] = change.service
                  break
                case ChangeType.DELETE:
                  delete draft.services[change.service.serviceName]
                  break
              }
            })
            draft.lastDataTS = new Date().toISOString()
          }),
          false
        ),

      appendLogGroups: (logGroups: LogGroupChange[], clean?: boolean) =>
        set(
          produce((draft) => {
            draft.logGroups = clean ? {} : draft.logGroups || {}
            logGroups.forEach((change) => {
              switch (change.changeType) {
                case ChangeType.CREATE:
                case ChangeType.UPDATE:
                  draft.logGroups[change.logGroup.logGroupId] = change.logGroup
                  break
                case ChangeType.DELETE:
                  delete draft.logGroups[change.logGroup.logGroupId]
                  break
              }
            })
            draft.lastDataTS = new Date().toISOString()
          }),
          false
        ),

      clear: () =>
        set(
          {
            lastDataTS: undefined,
            darkMode: true,
            bookmarks: true,
            notificationsOpen: false,
            services: undefined,
            logGroups: undefined,
            user: undefined,
            notifications: []
          },
          false
        )
    }),
    {
      name: 'obs',
      getStorage: () => localForage as any,
      onRehydrateStorage: () => (state) => state?.setHasHydrated(true)
    }
  )
)
