import tw from 'tailwind-styled-components'
import { useNavigate } from 'react-router-dom'
import { INotification } from '../../../interfaces'
import { useCallback } from 'react'
import { useObsStore } from '../../../store'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/solid'

export interface INotificationItemProps {
  notification: INotification
}

export const NotificationItem: React.FC<INotificationItemProps> = ({ notification }) => {
  const navigate = useNavigate()
  const { message, url, type, title, timestamp } = notification
  const [removeNotification, toggleNotifications] = useObsStore(
    useCallback((state) => [state.removeNotification, state.toggleNotifications], [])
  )
  const navigateToUrl = useCallback(() => {
    navigate({ pathname: url })
    toggleNotifications('')
  }, [navigate, url, toggleNotifications])

  const dismissNotification = useCallback(
    () => removeNotification(notification.id),
    [notification.id, removeNotification]
  )

  return (
    <Notification>
      <NotificationIcon aria-label='group icon' role='img' $type={type}>
        {type === 'info' ? <InformationCircleIcon /> : <ExclamationCircleIcon />}
      </NotificationIcon>
      <div className='pl-3 w-full space-y-2'>
        <div className='flex items-center justify-between w-full'>
          <p className='text-md leading-none'>{title}</p>
          <button type='button' aria-label='close icon' className='cursor-pointer' onClick={dismissNotification}>
            <XMarkIcon className='w-4 h-4' />
          </button>
        </div>
        <p className='text-sm leading-none'>{message}</p>
        <div className='flex items-center justify-between w-full'>
          <p className='text-xs leading-3 pt-1 text-gray-500 dark:text-white'>{timestamp}</p>
          <NavigateText onClick={navigateToUrl}>Show details</NavigateText>
        </div>
      </div>
    </Notification>
  )
}

const NotificationIcon = tw.div<{ $type: string }>`
  ${(p: any) => (p.$type === 'info' ? '' : 'text-red-700')}
  w-8 
  h-8 
  rounded-full 
  flex 
  flex-shrink-0 
  items-center 
  justify-center
`

const NavigateText = tw.p`
  cursor-pointer 
  w-fit 
  leading-none 
  text-sm 
  m-0 
  text-gray-900 
  dark:text-gray-200 
  underline 
  underline-offset-4
`

const Notification = tw.div`
  text-left 
  w-full 
  max-w-sm 
  p-3 
  mx-4 
  bg-white 
  rounded 
  shadow-lg 
  flex 
  flex-shrink-0
  items-center 
  dark:bg-gray-600
  dark:text-white
`
