import tw from 'tailwind-styled-components'
import React, { ChangeEvent, PropsWithChildren } from 'react'

export interface ISelectProps extends PropsWithChildren {
  id: string
  name: string
  label?: string
  placeholder?: string
  value: string
  valueUpdated: (value: string) => void
}

export const Select: React.FC<ISelectProps> = ({ id, name, label, children, placeholder, value, valueUpdated }) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label htmlFor={id} className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200'>
          {label}
        </label>
      )}
      <TWSelect
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => valueUpdated(e.target.value)}
      >
        {children}
      </TWSelect>
    </div>
  )
}

const TWSelect = tw.select`
    text-left 
    min-w-fit 
    w-full
    bg-gray-50 
    border 
    border-gray-300 
    text-gray-900 
    text-sm 
    rounded-lg 
    block 
    p-2.5 
    dark:bg-gray-700 
    dark:border-gray-600 
    dark:placeholder-gray-400 
    dark:text-white 
`
