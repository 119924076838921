import { StatusIndicator } from '../StatusIndicator'
import { HealthStatus } from '../../interfaces'
import tw from 'tailwind-styled-components'

export interface IServiceHealthTimelineProps {
  items: { health: HealthStatus; timestamp: string }[]
}

const getTimelineColor = (status: HealthStatus) => {
  switch (status) {
    case HealthStatus.UP:
      return 'bg-lime-700'
    case HealthStatus.DOWN:
      return 'bg-red-700'
    case HealthStatus.OUT_OF_SERVICE:
      return 'bg-amber-600'
    case HealthStatus.UNKNOWN:
    default:
      return 'bg-gray-900'
  }
}

export const ServiceHealthTimeline: React.FC<IServiceHealthTimelineProps> = ({ items }) => {
  return (
    <div className='overflow-scroll pl-5 pt-6' style={{ maxHeight: '65vh' }}>
      <Timeline>
        {items.map((item, idx) => (
          <li className='mb-10 ml-4 dark:text-white' key={item.timestamp}>
            <StatusCircle $color={getTimelineColor(item.health)} />
            <StatusIndicator status={item.health} /> on {item.timestamp}
            {idx === 0 && <LatestBadge>Latest</LatestBadge>}
          </li>
        ))}
      </Timeline>
    </div>
  )
}

const Timeline = tw.ol`
  relative 
  border-l 
  border-gray-700 
  dark:border-white 
  text-left
`

const LatestBadge = tw.span`
  bg-gray-700 
  dark:bg-gray-300 
  dark:text-gray-900 
  text-white 
  text-sm 
  font-medium 
  mr-2 
  px-2.5 
  py-0.5 
  rounded ml-3
`

const StatusCircle = tw.div<{ $color: string }>`
  ${(p: any) => p.$color}
  absolute 
  w-3 h-3 
  rounded-full 
  mt-1 
  -left-1.5 
  border 
  border-gray-900 
  dark:border-white
`
