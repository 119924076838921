import { STORAGE_ID_TOKEN_KEY } from '../../config/constants'
import { refreshDashboardData } from '../bffApi/initData'
import { logger } from '../logger'
import { useObsStore } from '../../store'
import { ChangeType, IMessage, IWSEvent } from '../../interfaces'
import API from '../bffApi'
import { v4 } from 'uuid'

class BffWebSocket {
  private attempt: number = 0
  private lastEvent: string | undefined
  public socket: WebSocket | undefined

  constructor(private readonly reconnectAttempts: number) {}

  async initialize() {
    if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
      logger.info('Socket already initialized')
      return
    }

    try {
      logger.info('Initializing the Socket')
      const idToken = localStorage.getItem(STORAGE_ID_TOKEN_KEY)

      this.socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL!}?idToken=${idToken}`)

      this.socket.onopen = this.handleConnectionOpen
      this.socket.onmessage = this.handleMessage
      this.socket.onclose = this.handleConnectionClose

      this.socket.onerror = (err: any) => {
        logger.error('Socket error', err)
        this.socket?.close()
      }
    } catch (error: any) {
      logger.error('Could not initialize the Socket', error)
    }
  }

  handleConnectionOpen = () => {
    logger.info('Opened the Socket connection')
    this.attempt = 0

    if (this.lastEvent) {
      logger.info('Reconnected the Socket. Refreshing data', this.lastEvent)
      this.refreshData()
    }
  }

  handleConnectionClose = () => {
    logger.info('Websocket closed ' + this.attempt)
    this.lastEvent = new Date().toISOString()
    if (this.attempt < this.reconnectAttempts) {
      this.attempt += 1

      // this is most likely not a network issue at this point. Try refresh the token
      if (this.attempt > 3) {
        API.refreshToken()
      }

      logger.info(`Attempt ${this.attempt}, reconnect in ${3 + 0.5 * this.attempt ** 2}s`)
      setTimeout(() => this.initialize(), 1000 * (3 + 0.5 * this.attempt ** 2))
    } else {
      this.attempt = 0
    }
  }

  handleMessage = (event: MessageEvent) => {
    this.lastEvent = new Date().toISOString()
    const data = JSON.parse(event.data) as IWSEvent
    if (data.events.length === 1 && data.events[0].eventType === 'PING') {
      logger.verbose('websocket ping received')
    } else {
      logger.info('Received websocket data')
      data.events.forEach(this.processSingleEvent)
    }
  }

  processSingleEvent = (event: IMessage) => {
    try {
      const { appendServices, appendLogGroups, addNotification, updateUser, user, bookmarks } = useObsStore.getState()
      if (event.service) {
        if ((bookmarks && user?.showServices?.includes(event.service.serviceName)) || !bookmarks) {
          addNotification({
            id: v4(),
            type: 'info',
            title: event.service.serviceName,
            message: 'Received an update',
            url: `/services/${encodeURIComponent(event.service.serviceName)}`,
            timestamp: new Date().toLocaleString()
          })
          appendServices([{ changeType: event.eventType as ChangeType, service: event.service }])
        }
      } else if (event.logGroup) {
        if ((bookmarks && user?.showServices?.includes(event.logGroup.serviceName)) || !bookmarks) {
          appendLogGroups([{ changeType: event.eventType as ChangeType, logGroup: event.logGroup }])
          addNotification({
            id: v4(),
            type: 'info',
            title: event.logGroup.logGroupName,
            message: `Log group belonging to ${event.logGroup.serviceName} received an update`,
            url: `/loggroups/${encodeURIComponent(event.logGroup.logGroupId)}`,
            timestamp: new Date().toLocaleString()
          })
        }
      } else if (event.user) {
        updateUser(event.user)
      } else if (event.logEvents) {
        logger.info('Received new log events', event.logEvents)
        const logGroupNameComponents = event.logEvents.logGroupId.split(':')
        const logGroupName = logGroupNameComponents.length >= 3 ? logGroupNameComponents[2] : event.logEvents.logGroupId
        addNotification({
          id: v4(),
          type: 'error',
          title: logGroupName,
          message: `The log group reported an error`,
          url: `/loggroups/${encodeURIComponent(event.logEvents.logGroupId)}`,
          timestamp: new Date().toLocaleString()
        })
      }
    } catch (error: any) {
      logger.error('Could not process event update', event)
    }
  }

  async refreshData() {
    try {
      await refreshDashboardData(this.lastEvent)
    } catch (error: any) {
      logger.error('Could not initialize data', error)
    }
  }

  close() {
    try {
      this.attempt = this.reconnectAttempts
      this.lastEvent = undefined
      this.socket?.close()
    } catch (error: any) {
      logger.error('Could not close socket connection', error)
    }
  }
}

export default new BffWebSocket(10)
