import { toast } from 'react-hot-toast'
import { ToastBanner } from '../components/toast/ToastBanner'
import { logger } from '../services/logger'
import axios from 'axios'

export interface IExecuteWithToastOptions {
  shouldThrow?: boolean
  loadingMessage?: string
  successMessage?: string
  failureMessage?: string
}

const DEFAULT_TIMEOUT = 300
const DEFAULT_TOAST_DURATION = 3000

export const executeWithToast = async (handler: () => Promise<any>, options: IExecuteWithToastOptions) => {
  const { loadingMessage, successMessage, failureMessage } = options
  let toastId: string | undefined

  if (loadingMessage) {
    toastId = toast.loading(loadingMessage)
  }

  try {
    await handler()

    if (successMessage) {
      setTimeout(
        () =>
          toast.custom((t) => <ToastBanner type='success' t={t} message={successMessage} />, {
            id: toastId,
            duration: DEFAULT_TOAST_DURATION
          }),
        DEFAULT_TIMEOUT
      )
    } else {
      toast.dismiss(toastId)
    }
  } catch (error: any) {
    logger.error(options.failureMessage, error)
    const isAxiosCancel = axios.isCancel(error)
    if (failureMessage && !isAxiosCancel) {
      setTimeout(
        () =>
          toast.custom((t) => <ToastBanner t={t} message={failureMessage} type='error' />, {
            id: toastId,
            duration: DEFAULT_TOAST_DURATION
          }),
        DEFAULT_TIMEOUT
      )
      if (options.shouldThrow && !isAxiosCancel) {
        throw error
      }
    } else {
      toast.dismiss(toastId)
    }
  }
}
