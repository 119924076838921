import { ILogEventsGroup } from '../../../interfaces'
import { getLocaleTimestamp, prettyPrintJSONString } from '../../../helpers/statusCardHelpers'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { Divider } from '../../common/Divider'

export interface ILogEventGroupItem {
  logEventGroup: ILogEventsGroup
}

export const LogEventGroupItem: React.FC<ILogEventGroupItem> = ({ logEventGroup }) => {
  return (
    <div>
      <p className='dark:text-white flex flex-inline'>
        <b>{logEventGroup.logStreamName}</b>
        <a href={logEventGroup.logStreamLink} target='_blank' rel='noreferrer'>
          <ArrowTopRightOnSquareIcon className='ml-2 text-gray-900 dark:text-gray-200 w-5 h-5' />
        </a>
      </p>
      {logEventGroup.logEvents.map((logEvent) => {
        const logEventEntry =
          `Log ID: ${logEvent.id}\n` +
          `Timestamp: ${getLocaleTimestamp(logEvent.timestamp)}\n` +
          `Message:\n${prettyPrintJSONString(logEvent.message)}`
        return (
          <div key={logEvent.id}>
            <pre className='whitespace-pre dark:text-white p-5 text-xl md:text-lg lg:text-sm'>{logEventEntry}</pre>
            <Divider />
          </div>
        )
      })}
    </div>
  )
}
