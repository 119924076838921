import { createNativeFeatureFlags } from '@slatldisal/feature-flags'
import API from './index'
import { ChangeType, IService, IUser, IFlags } from '../../interfaces'
import { useObsStore } from '../../store'
import { logger } from '../logger'
import { Auth } from '@aws-amplify/auth'

const getTeams = async (): Promise<string[]> => {
  try {
    const claims = await Auth.currentUserInfo()
    const teamClaim: string = claims.attributes['custom:team'] ?? ''
    return teamClaim.trim().split(',')
  } catch (error: any) {
    logger.error("Couldn't get user team", error)
    return []
  }
}

const loadServices = async (clean: boolean, since?: string) => {
  const { appendServices, bookmarks, user } = useObsStore.getState()
  logger.info('Initializing services data', since)
  const serviceData = await API.getServiceData(since)
  const services =
    serviceData?.services.filter(({ serviceName }) => (bookmarks ? user?.showServices?.includes(serviceName) : true)) ??
    []
  appendServices(
    services.map((item: IService) => ({ service: item, changeType: ChangeType.CREATE })),
    clean
  )

  logger.info(`Initialized services: ${services.length}`)
}

const loadLogGroups = async (clean: boolean, since?: string) => {
  const { appendLogGroups, services } = useObsStore.getState()
  logger.info('Initializing logs data', since)

  const serviceNames = Object.values(services ?? []).map((service) => service.serviceName)
  const logGroupPromises = serviceNames.map((serviceName) => API.getLogGroupData(serviceName, since))
  const logGroupResults = await Promise.allSettled(logGroupPromises)

  const logGroups = logGroupResults
    .flatMap((logGroupPromise) =>
      logGroupPromise.status === 'fulfilled' && logGroupPromise.value ? logGroupPromise.value?.logGroups : []
    )
    .map((item) => ({ logGroup: item, changeType: ChangeType.CREATE }))
  appendLogGroups(logGroups, clean)
  logger.info(`Initialized logGroups ${logGroups.length}`)
}

const loadFeatureFlags = async () => {
  if(process.env.REACT_APP_LAUNCH_DARKLY) {
    const flags = await createNativeFeatureFlags<IFlags>(process.env.REACT_APP_LAUNCH_DARKLY)
    const { updateFeatureFlags } = useObsStore.getState()
    logger.info('Initializing feature flags', flags)
    updateFeatureFlags(flags)
  }
}

const loadUser = async () => {
  const { updateUser, bookmarks } = useObsStore.getState()
  const currUser = await API.getUser()
  const userWithTeam: IUser = { ...currUser, teams: await getTeams() }
  logger.info('Initializing user data', userWithTeam, bookmarks)
  updateUser(userWithTeam)
}

export const refreshDashboardData = async (since?: string) => {
  try {
    await loadUser()
    await loadServices(false, since)
    await loadLogGroups(false, since)
    await loadFeatureFlags()
  } catch (error: any) {
    logger.error('Could not refresh dashboard data', error)
  }
}

export const initializeDashboardData = async () => {
  try {
    await loadUser()
    await loadServices(true)
    await loadLogGroups(true)
    await loadFeatureFlags()
  } catch (error: any) {
    logger.error('Could not initialize dashboard data', error)
  }
}
