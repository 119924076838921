import tw from 'tailwind-styled-components'
import { XMarkIcon } from '@heroicons/react/20/solid'

export interface IInlinedNotificationProps {
  error: string | null
  dismiss: () => void
}

export const InlinedNotification: React.FC<IInlinedNotificationProps> = ({ error, dismiss }) => {
  if (!error) {
    return <></>
  }
  return (
    <Notification role='alert'>
      <span className='my-auto align-middle'>
        <span className='font-medium'>Authentication error:</span> {error}
        <DismissButton type='button' aria-label='Close' onClick={dismiss}>
          <span className='sr-only'>Close</span>
          <XMarkIcon className='w-5 h-5' />
        </DismissButton>
      </span>
    </Notification>
  )
}

const DismissButton = tw.button`
  ml-auto 
  bg-red-100 
  text-red-500 
  rounded-lg 
  inline-flex
  align-middle
  mb-1 
  dark:bg-red-200 
  dark:text-red-600
`

const Notification = tw.div`
  absolute
  top-24
  p-4
  text-sm 
  text-red-700 
  bg-red-100 
  rounded-lg 
  dark:bg-red-200 
  dark:text-red-800
`
