import { withLDProvider, useFlags as useLaunchDarklyFlags } from 'launchdarkly-react-client-sdk'
import * as JSLDClient from 'launchdarkly-js-client-sdk'

import React from 'react'

const isTest = typeof jest !== 'undefined'

export function createFeatureFlags<T>(
  launchDarklyId: string,
  defaultTestFlags: T,
  localOverrides: Partial<T> = {},
  streaming = false
) {
  let testFlags = undefined as Partial<T> | undefined

  return {
    withFlags: <P>(wrappedComponent: React.ComponentType<P>) =>
      isTest
        ? wrappedComponent
        : withLDProvider({
            clientSideID: launchDarklyId,
            options: { streaming }
          })(wrappedComponent as any),

    useFlags: () => {
      if (testFlags) {
        return { ...defaultTestFlags, ...testFlags }
      }

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const ldFlags = (isTest ? {} : useLaunchDarklyFlags()) as T
      if (window.location.hostname !== 'localhost') {
        return ldFlags
      }

      return { ...ldFlags, ...localOverrides }
    },

    setFlagsForTesting: (testingFlags: Partial<T>) => {
      testFlags = testingFlags
    }
  }
}

export interface IFlagsProvider<T> {
  getFlag<K extends keyof T>(key: K, defaultValue: T[K]): T[K]
}

export type FeatureFlags<T> = IFlagsProvider<T> & Readonly<T>

export async function createNativeFeatureFlags<T>(
  launchDarklyId: string,
  localOverrides: Partial<T> = {}
): Promise<FeatureFlags<T>> {
  let flags = {} as T

  if (!isTest) {
    const ldClient = JSLDClient.initialize(launchDarklyId, { anonymous: true })
    await ldClient.waitForInitialization()
    flags = { ...ldClient.allFlags(), ...localOverrides } as T
  }

  return {
    ...flags,
    getFlag: (key, defaultValue) => flags[key] ?? defaultValue
  }
}
