import { HealthStatus, IServiceConfig, IUpdateServiceRequest, NotificationContentType } from '../../../interfaces'
import { UncontrolledCheckbox } from '../../common/Checkbox'
import { UncontrolledInput } from '../../common/Input'
import { Button } from '../../common/Button'
import { Headline } from '../../common/Headline'
import { Divider } from '../../common/Divider'
import { useForm } from 'react-hook-form'
import { InlinedNotification } from '../../InlinedNotification'
import React, { useCallback, useState } from 'react'
import API from '../../../services/bffApi'
import { executeWithToast } from '../../../helpers/executionUtils'

export interface IServiceConfigProps {
  serviceName: string
  config?: IServiceConfig
}

interface IConfigFormData {
  enabled: boolean
  slackWebhook: string
  subscribedContent_LOGS: boolean
  subscribedContent_HEALTH: boolean
  subscribedHealth_UP: boolean
  subscribedHealth_DOWN: boolean
  subscribedHealth_OUT_OF_SERVICE: boolean
  subscribedHealth_UNKNOWN: boolean
}

const toastConfig = {
  loadingMessage: 'Updating service config',
  successMessage: 'Updated the service config',
  failureMessage: 'Could not update service config'
}

export const ServiceConfig: React.FC<IServiceConfigProps> = ({ serviceName, config }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [requestError, setRequestError] = useState<string | null>(null)
  const onDismiss = useCallback(() => setRequestError(null), [])

  const { handleSubmit, register } = useForm({
    defaultValues: {
      enabled: config?.notifications?.enabled ?? false,
      slackWebhook: config?.notifications?.slackWebhook ?? '',
      subscribedContent_LOGS: hasSubscribedContent(config, NotificationContentType.LOGS),
      subscribedContent_HEALTH: hasSubscribedContent(config, NotificationContentType.HEALTH),
      subscribedHealth_UP: hasSubscribedHealth(config, HealthStatus.UP),
      subscribedHealth_DOWN: hasSubscribedHealth(config, HealthStatus.DOWN),
      subscribedHealth_OUT_OF_SERVICE: hasSubscribedHealth(config, HealthStatus.OUT_OF_SERVICE),
      subscribedHealth_UNKNOWN: hasSubscribedHealth(config, HealthStatus.UNKNOWN)
    }
  })

  const doSubmit = useCallback(
    async (data: IConfigFormData) => {
      const onSubmit = async () => {
        setLoading(true)
        const subscribedContent = Object.values(NotificationContentType).filter(
          (type: NotificationContentType) => data[`subscribedContent_${type}` as keyof IConfigFormData]
        )
        const subscribedHealthStatuses = Object.values(HealthStatus).filter(
          (status: HealthStatus) => data[`subscribedHealth_${status}` as keyof IConfigFormData]
        )

        const request: IUpdateServiceRequest = {
          config: {
            notifications: {
              enabled: data.enabled,
              slackWebhook: data.slackWebhook,
              subscribedContent,
              subscribedHealthStatuses
            }
          }
        }

        await API.updateService(serviceName, request)
      }
      await executeWithToast(onSubmit, toastConfig).finally(() => setLoading(false))
    },
    [serviceName]
  )

  if (!config || !config.notifications) {
    return <></>
  }

  return (
    <>
      <div className='w-fit mx-auto'>
        <InlinedNotification error={requestError} dismiss={onDismiss} />
      </div>
      <div className='flex flex-col mx-auto md:w-2/3 w-full'>
        <form className='text-left mt-8 space-y-6' onSubmit={handleSubmit(doSubmit)}>
          <div>
            <Headline>Notifications</Headline>

            <div className='-space-y-px rounded-md shadow-sm w-full'>
              <UncontrolledCheckbox name='enabled' label='Enabled' id='enabled' register={register} />
            </div>

            <div className='-space-y-px rounded-md shadow-sm w-full'>
              <UncontrolledInput
                id='slackWebhook'
                name='slackWebhook'
                type='text'
                placeholder='Slack Webhook'
                label='Slack Webhook'
                register={register}
              />
            </div>
          </div>

          <Divider />

          <div className='-space-y-px w-full'>
            <p className='text-lg dark:text-white'>Subscribed Content</p>
            <div className='flex gap-4'>
              {Object.values(NotificationContentType).map((type) => (
                <UncontrolledCheckbox
                  key={type}
                  id={`subscribedContent_${type}`}
                  name={`subscribedContent_${type}`}
                  label={type}
                  register={register}
                />
              ))}
            </div>
          </div>

          <Divider />

          <div className='-space-y-px w-full'>
            <p className='text-lg dark:text-white'>Subscribed Health Status</p>
            <div className='flex flex-col sm:flex-row gap-4'>
              {Object.values(HealthStatus).map((status) => (
                <UncontrolledCheckbox
                  key={status}
                  id={`subscribedHealth_${status}`}
                  name={`subscribedHealth_${status}`}
                  label={status}
                  register={register}
                />
              ))}
            </div>
          </div>

          <Divider />

          <Button clickHandler={handleSubmit(doSubmit)} disabled={loading} loading={loading} label='Save' primary />
        </form>
      </div>
    </>
  )
}

const hasSubscribedContent = (config: IServiceConfig | undefined, notification: NotificationContentType): boolean =>
  config?.notifications?.subscribedContent?.includes(notification) ?? false

const hasSubscribedHealth = (config: IServiceConfig | undefined, health: HealthStatus): boolean =>
  config?.notifications?.subscribedHealthStatuses?.includes(health) ?? false
