import { Dialog } from '@headlessui/react'
import { Button } from '../common/Button'

export interface IConfirmationModal {
  modalOpen: boolean
  closeModal: () => void
  heading: string
  message: string
  confirm: { label: string; action: () => void }
  cancel: { label: string; action: () => void }
}

export const ConfirmationModal: React.FC<IConfirmationModal> = ({
  heading,
  message,
  modalOpen,
  closeModal,
  confirm,
  cancel
}) => {
  return (
    <Dialog as='div' className='relative z-10' onClose={closeModal} open={modalOpen}>
      <div className='fixed inset-0 overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4 text-center w-full'>
          <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
            <Dialog.Title as='h3' className='text-center text-lg font-medium leading-6 text-gray-900'>
              {heading}
            </Dialog.Title>
            <div className='mt-2'>
              <p className='text-center text-gray-500'>{message}</p>
            </div>

            <div className='mt-4 flex w-full items-center justify-center'>
              <Button clickHandler={cancel.action} label={cancel.label} />
              <Button primary clickHandler={confirm.action} label={confirm.label} />
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
