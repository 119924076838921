/*
case 'OK':
    case 'UP':
      return 'bg-lime-700'
    case 'ERROR':
    case 'DOWN':
      return 'bg-red-700'
    case 'OUT_OF_SERVICE':
      return 'bg-amber-600'
    case 'UNKNOWN':
    default:
      return 'bg-gray-900'
 */

const getStatisticColor = (statusColor: string) => {
  switch (statusColor) {
    case 'red':
      return 'text-red-700 dark:text-red-400'
    case 'green':
      return 'text-lime-700 dark:text-lime-400'
    case 'orange':
      return 'text-orange-500 dark:text-orange-400'
    case 'gray':
      return 'text-gray-500 dark:text-gray-400'
    case 'black':
      return 'text-gray-900 dark:text-white'
  }
}

/*
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
 */

export interface IStatisticProps {
  color: 'red' | 'green' | 'gray' | 'orange' | 'black'
  value: string | number
}

export const Statistic: React.FC<IStatisticProps> = ({ color, value }) => {
  const twColor = getStatisticColor(color)

  return (
    <div className='box-border m-0 p-0 text-lg tabular-nums'>
      <span className={`${twColor}`}>{value}</span>
    </div>
  )
}
