import React, { PropsWithChildren } from 'react'

export interface IColorSpanProps extends PropsWithChildren {
  color: 'red' | 'green' | 'gray' | 'black'
  weight?: 'bold' | 'semibold' | 'medium' | 'normal'
  uppercase?: boolean
}

const getTwColorClass = (statusColor: string) => {
  switch (statusColor) {
    case 'red':
      return 'text-red-700 dark:text-red-400'
    case 'green':
      return 'text-lime-700 dark:text-lime-400'
    case 'gray':
      return 'text-gray-700 dark:text-gray-300'
    case 'black':
      return 'text-gray-900 dark:text-white'
  }
}

export const ColorText: React.FC<IColorSpanProps> = ({ color, uppercase, children, weight = 'normal' }) => {
  const twColor = getTwColorClass(color)
  return <span className={`${twColor} ${uppercase ? 'uppercase' : ''} font-${weight}`}>{children}</span>
}
