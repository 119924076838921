import { ChangeEventHandler } from 'react'
import tw from 'tailwind-styled-components'
import { UseFormRegister } from 'react-hook-form'

export interface IInputProps {
  id: string
  name: string
  type: string
  label?: string
  autoComplete?: string
  autoFocus?: boolean
  required?: boolean
  placeholder?: string
  value: string
  valueUpdated: (value: string) => void
}

export interface IUncontrolledInputProps {
  id: string
  name: string
  type: string
  label?: string
  autoComplete?: string
  autoFocus?: boolean
  required?: boolean
  placeholder?: string
  register: UseFormRegister<any>
}

export const Input: React.FC<IInputProps> = ({
  id,
  name,
  label,
  type,
  autoComplete,
  autoFocus,
  required,
  placeholder,
  value,
  valueUpdated
}) => {
  const changeFunction: ChangeEventHandler<HTMLInputElement> = (event) => valueUpdated(event.target.value)

  return (
    <div>
      {label && (
        <label htmlFor={id} className='sr-only'>
          {label}
        </label>
      )}
      <TWInput
        id={id}
        name={name}
        type={type}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={changeFunction}
      />
    </div>
  )
}

export const UncontrolledInput: React.FC<IUncontrolledInputProps> = ({
  id,
  name,
  label,
  type,
  autoComplete,
  autoFocus,
  required,
  placeholder,
  register
}) => {
  return (
    <div>
      {label && (
        <label htmlFor={id} className='sr-only'>
          {label}
        </label>
      )}
      <TWInput
        id={id}
        type={type}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        required={required}
        placeholder={placeholder}
        {...register(name, { required })}
      />
    </div>
  )
}

const TWInput = tw.input`
  relative
  block 
  w-full 
  appearance-none 
  rounded-none 
  rounded-t-md 
  bg-gray-50 
  border 
  border-gray-300 
  px-3 
  py-2 
  text-gray-900 
  placeholder-gray-500 
  sm:text-sm
  dark:bg-gray-700 
  dark:border-gray-600 
  dark:placeholder-gray-400 
  dark:text-white
`
