import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'flowbite'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Amplify } from '@aws-amplify/core'
import { AuthProvider } from './config/auth'

Amplify.Logger.LOG_LEVEL = process.env.NODE_ENV === 'production' ? 'ERROR' : 'INFO'

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    storage: localStorage
  }
})

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
