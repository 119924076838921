import { CircleStackIcon } from '@heroicons/react/24/solid'

export interface INoDataProps {
  text?: string
}

export const NoData: React.FC<INoDataProps> = ({ text = 'No Data' }) => {
  return (
    <div className='flex flex-col text-center items-center w-full p-16'>
      <CircleStackIcon className='w-10 h-10 text-gray-900 dark:text-white' />
      <p className='dark:text-white text-gray-900'>{text}</p>
    </div>
  )
}
