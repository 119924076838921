import API from '../../../services/bffApi'
import { IAppVersion } from '../../../interfaces'


export const fetchReleases = async (serviceName: string): Promise<{ [version: string]: IAppVersion[] }> => {
  const releases = await API.getServiceReleases(serviceName)
  return releases.reduce((acc: { [version: string]: IAppVersion[] }, curr: IAppVersion) => {
    if (!acc[curr.commitSha]) {
      acc[curr.commitSha] = []
    }
    acc[curr.commitSha].push(curr)
    return acc
  }, {})
}
