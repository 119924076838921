export interface IContactInfo {
  name: string
  email: string
}

export enum LogErrorStatus {
  OK = 'OK',
  ERROR = 'ERROR'
}

export enum HealthStatus {
  UP = 'UP',
  DOWN = 'DOWN',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  UNKNOWN = 'UNKNOWN'
}

export enum ChangeType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export type PingType = 'PING'

export interface IHealthStatus {
  status: HealthStatus
  components?: {
    [serviceName: string]: IHealthStatus
  }
  details?: any
}

export interface IAppVersion {
  committer: string
  deployedBy: string
  commitSha: string
  commitMessage: string
  commitTime: string
  completedAt: string
  env: string
  serviceName: string
  diffLink: string
  namespace: string
  module: string
}

export interface ILogGroup {
  awsAccount: string
  awsRegion: string
  logGroupName: string
  logGroupLink?: string
  logErrorStatus?: LogErrorStatus
  lastLogEventsWithErrorsTS?: string
  lastUpdateTS: string
  serviceName: string
  logGroupId: string
}

export interface IService {
  serviceName: string
  contactInfo?: IContactInfo
  team?: string
  logErrorStatus?: LogErrorStatus
  lastLogEventsWithErrorsTS?: string
  healthStatus: IHealthStatus
  lastHealthTS?: string
  lastLogGroupTS?: string
  lastUpdateTS: string
  config: IServiceConfig
  currentVersion?: IAppVersion
}

export interface IServiceConfig {
  notifications?: INotificationConfig
}

export interface INotificationConfig {
  enabled: boolean
  slackWebhook: string
  subscribedContent: Array<NotificationContentType>
  subscribedHealthStatuses: Array<HealthStatus>
}

export enum NotificationContentType {
  LOGS = 'LOGS',
  HEALTH = 'HEALTH'
}

export interface ILogEvent {
  id: string
  timestamp: number
  message: string
}

export interface ILogEventsGroup {
  logGroupId: string
  logStreamName: string
  logStreamLink?: string
  logEvents: ILogEvent[]
  lastLogEventTS: string
  lastErrorTS?: string
  lastUpdateTS: string
}

export interface IUser {
  email: string
  showServices?: string[]
  otp: boolean
  teams: string[]
  lastUpdateTS: string
}

export interface IWSEvent {
  events: IMessage[]
}

export interface IMessage {
  eventType: ChangeType | PingType
  service?: IService
  logGroup?: ILogGroup
  logEvents?: ILogEventsGroup
  user?: IUser
}

export interface IServiceHealth {
  serviceName: string
  healthStatus: IHealthStatus
  healthStatusTS: string
}

export interface IUpdateServiceRequest {
  config: IServiceConfig
}

export interface INotification {
  id: string
  type: 'info' | 'error'
  title: string
  message: string
  timestamp: string
  url: string
}
export interface IPromoteRelease {
  namespace: string
  module: string
  ref: string
  target: 'pp' | 'p'
  promoteFrom: 'dev' | 'pp' | 'p'
}

export interface IFlags {
    'enable-new-releases': boolean
}