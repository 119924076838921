import QRCode from 'qrcode'
import { useCallback, useEffect, useState } from 'react'
import { IUser } from '../../interfaces'
import bffApi from '../../services/bffApi'
import { logger } from '../../services/logger'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import { Headline } from '../../components/common/Headline'
import { Divider } from '../../components/common/Divider'
import { Checkbox } from '../../components/common/Checkbox'
import { Input } from '../../components/common/Input'
import { Button } from '../../components/common/Button'
import { InlinedNotification } from '../../components/InlinedNotification'
import { useObsStore } from '../../store'
import { NoData } from '../../components/common/NoData'

const ProfilePage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [user, updateUser] = useObsStore((state) => [state.user, state.updateUser])
  const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false)
  const [otpAuth, setOtpAuth] = useState<string | null>(null)
  const [otpCode, setOtpCode] = useState<string>('')
  const [otpCodeError, setOtpCodeError] = useState<string | null>(null)
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const openModal = useCallback(() => setConfirmModalOpen(true), [setConfirmModalOpen])
  const closeModal = useCallback(() => setConfirmModalOpen(false), [setConfirmModalOpen])
  const onDismiss = useCallback(() => setOtpCodeError(null), [])

  useEffect(() => {
    if (user?.otp) {
      setUseAuthenticator(true)
    }
  }, [user])

  const deleteOtp = async () => {
    await bffApi.deleteOtp()
    updateUser({ ...user, otp: true } as IUser)
  }

  const onLoginWithAuthenticatorClicked = async () => {
    const checked = useAuthenticator
    setUseAuthenticator((previousValue) => !previousValue)
    if (checked) {
      const otpAuth = await bffApi.setupOtp()
      setOtpAuth(otpAuth)

      try {
        await QRCode.toCanvas(document.getElementById('qrCanvas'), otpAuth, { scale: 7 })
      } catch (err) {
        logger.error('Failed to generate QR Code.')
        setOtpCodeError('Failed to generate QR Code.')
      }
    } else if (otpAuth) {
      onCancelOtp()
    } else {
      openModal()
    }
  }

  // e: any, because different event types don't play well together
  const onSubmitOtp = async (e: any) => {
    if (isFormEvent(e)) {
      e.preventDefault()
    }

    setLoading(true)

    try {
      await bffApi.confirmOtp(otpCode)
      setOtpAuth(null)
      setOtpCode('')
      setOtpCodeError(null)
      updateUser({ ...user, otp: true } as IUser)

      // TODO: Remove QR Code.
      const canvas = document.getElementById('qrCanvas')! as HTMLCanvasElement
      const context = canvas.getContext('2d')
      context!.clearRect(0, 0, 0, 0)
    } catch (err) {
      setOtpCodeError('Bad code.')
    } finally {
      setLoading(false)
    }
  }

  const onCancelOtp = async () => {
    setUseAuthenticator(false)
    setOtpAuth(null)
    setOtpCode('')
    setOtpCodeError(null)

    // TODO: Remove QR Code.
    const canvas = document.getElementById('qrCanvas')! as HTMLCanvasElement
    const context = canvas.getContext('2d')
    context!.clearRect(0, 0, canvas.width, canvas.height)

    try {
      await bffApi.cancelOtpSetup()
    } catch (err) {
      logger.error('Cancel OTP Setup failed.', err)
    }
  }

  if (!user) {
    return (
      <div className='pt-10 px-10 h-screen-no-scroll'>
        <NoData text='No current user' />
      </div>
    )
  }

  return (
    <div className='pt-10 px-10 h-screen-no-scroll'>
      <div className='flex items-center justify-center'>
        <InlinedNotification error={otpCodeError} dismiss={onDismiss} />
      </div>

      <Headline>Profile</Headline>
      <div className='flex flex-col gap-4'>
        <Divider />

        <Checkbox
          id='otp'
          name='login-with-authenticator'
          checked={useAuthenticator}
          valueUpdated={onLoginWithAuthenticatorClicked}
          label='Login with Authenticator (OTP)'
        />

        <canvas id='qrCanvas'></canvas>

        {otpAuth && (
          <>
            <form className='mt-8 space-y-6 flex flex-col justify-between' onSubmit={onSubmitOtp}>
              <div className='-space-y-px rounded-md shadow-sm'>
                <Input id='otpCode' name='otpCode' type='text' value={otpCode} valueUpdated={setOtpCode} />
              </div>

              <div>
                <Button disabled={loading} clickHandler={onCancelOtp} label='Cancel' />

                <Button clickHandler={onSubmitOtp} primary loading={loading} disabled={loading} label='Submit' />
              </div>
            </form>
          </>
        )}

        <Divider />
      </div>

      <ConfirmationModal
        modalOpen={confirmModalOpen}
        closeModal={closeModal}
        heading='Login with Authenticator?'
        message="Are you sure you don't want to Login with Authenticator?"
        confirm={{
          label: 'Confirm',
          action: () => {
            deleteOtp()
            setConfirmModalOpen(false)
          }
        }}
        cancel={{
          label: 'Cancel',
          action: () => {
            setUseAuthenticator(true)
            setConfirmModalOpen(false)
          }
        }}
      />
    </div>
  )
}

const isFormEvent = (e: any): e is React.FormEvent<HTMLFormElement> => {
  return e.preventDefault !== undefined
}

export default ProfilePage
