import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Input } from '../../common/Input'
import { Checkbox } from '../../common/Checkbox'
import { Button } from '../../common/Button'
import { LoginState } from '../../../pages/SignIn'

export interface ILoginFormProps {
  loginState: LoginState
  loading?: boolean
  usePasswordless: boolean
  updatePaswordless: (use: boolean) => void
  email: string
  updateEmail: (email: string) => void
  passcode: string
  updatePasscode: (passcode: string) => void
  onCancel: () => void
  onSubmit: () => void
}

export const LoginForm: React.FC<ILoginFormProps> = ({
  loginState,
  loading,
  email,
  passcode,
  updateEmail,
  updatePasscode,
  usePasswordless,
  updatePaswordless,
  onCancel,
  onSubmit
}) => {
  return (
    <div className='min-h-fit py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-h-screen space-y-8'>
        <div>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300'>
            Sign in to your account
          </h2>
        </div>
        <form className='mt-8 space-y-6 flex flex-col justify-between h-48' onSubmit={onSubmit}>
          {loginState === 'EMAIL_INPUT' && (
            <div className='space-y-4'>
              <div className='-space-y-px rounded-md shadow-sm'>
                <Input
                  id='email'
                  name='email'
                  type='email'
                  required
                  autoComplete='email'
                  placeholder='Your email'
                  autoFocus
                  label='Your email'
                  value={email}
                  valueUpdated={updateEmail}
                />
              </div>
              <Checkbox
                id='passwordless'
                name='passwordless'
                checked={usePasswordless}
                label='Use email link'
                valueUpdated={updatePaswordless}
              />
            </div>
          )}

          {loginState === 'CHALLENGE_INPUT' && (
            <div className='-space-y-px rounded-md shadow-sm'>
              <Input
                id='code'
                name='code'
                type='text'
                required
                placeholder='Code'
                autoFocus
                label='Code'
                value={passcode}
                valueUpdated={updatePasscode}
              />
            </div>
          )}

          <div>
            {loginState === 'CHALLENGE_INPUT' && <Button clickHandler={onCancel} label='Cancel' disabled={loading} />}
            <Button
              clickHandler={onSubmit}
              primary
              loading={loading}
              disabled={loading}
              label={loginState === 'EMAIL_INPUT' ? 'Continue' : 'Submit'}
              // @ts-ignore
              Icon={ChevronRightIcon}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
