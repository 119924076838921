import { toast, Toast, ToastType } from 'react-hot-toast'
import tw from 'tailwind-styled-components'
import { useCallback } from 'react'
import { CheckIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

export interface IToastBannerProps {
  t: Toast
  message: string
  type?: ToastType
}

export const ToastBanner: React.FC<IToastBannerProps> = ({ t, message, type = 'blank' }) => {
  const dismiss = useCallback(() => toast.dismiss(t.id), [t.id])
  return (
    <Notification>
      <div className='flex-1 w-0 p-2'>
        <div className='flex items-center justify-center'>
          {type === 'success' && <CheckIcon color='green' className='w-5 h-5 mr-3' />}
          {type === 'error' && <ExclamationTriangleIcon color='red' className='w-5 h-5 mr-3' />}
          {type === 'blank' && <InformationCircleIcon />}
          <p className='my-4 text-sm text-gray-900'>{message}</p>
        </div>
      </div>
      <div className='flex border-l border-gray-200'>
        <DismissButton onClick={dismiss}>Close</DismissButton>
      </div>
    </Notification>
  )
}

const Notification = tw.div`
  ${(p: any) => (p.$visible ? 'animate-enter' : 'animate-leave')}
  max-w-xs
  w-full 
  bg-white 
  shadow-lg 
  rounded-lg 
  pointer-events-auto 
  flex 
  ring-1 
  ring-black 
  ring-opacity-5
`

const DismissButton = tw.button`
  w-full 
  border 
  border-transparent 
  rounded-none 
  rounded-r-lg 
  p-4 
  flex 
  items-center 
  justify-center 
  text-sm 
  font-medium
`
