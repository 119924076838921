import { useParams } from 'react-router-dom'
import { useCallback, useState } from 'react'
import API from '../../services/bffApi'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import { LogGroup } from './LogGroup'
import { useObsStore } from '../../store'
import { executeWithToast } from '../../helpers/executionUtils'

const toastConfig = {
  loadingMessage: 'Clearing log group status',
  successMessage: 'Cleared log group status',
  failureMessage: 'Could not clear the log group status'
}

const LogGroupPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState<boolean>(false)
  const logGroupIdParam = decodeURIComponent(id ?? '')
  const logGroupId = logGroupIdParam.replaceAll('/', '\\')
  const [confirmClearModalOpen, setConfirmClearModalOpen] = useState<boolean>(false)
  const openModal = useCallback(() => setConfirmClearModalOpen(true), [setConfirmClearModalOpen])
  const closeModal = useCallback(() => setConfirmClearModalOpen(false), [setConfirmClearModalOpen])

  const logGroup = useObsStore(useCallback((state) => state.logGroups?.[logGroupId], [logGroupId]))

  const clearLogStatus = useCallback(() => {
    closeModal()
    setLoading(true)
    const clear = async () => await executeWithToast(() => API.clearLogGroupLogErrorStatus(logGroupId), toastConfig)
    clear().finally(() => setLoading(false))
  }, [closeModal, logGroupId])

  return (
    <>
      <LogGroup logGroup={logGroup} openModal={openModal} loading={loading} />
      <ConfirmationModal
        modalOpen={confirmClearModalOpen}
        closeModal={closeModal}
        heading='Are you sure you want to clear the log error status?'
        message=''
        confirm={{ label: 'Clear', action: clearLogStatus }}
        cancel={{ label: 'Cancel', action: () => setConfirmClearModalOpen(false) }}
      />
    </>
  )
}

export default LogGroupPage
