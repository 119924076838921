import React, { useEffect, useMemo, useState } from 'react'
import API from '../../../services/bffApi'
import { IServiceHealth } from '../../../interfaces'
import { Headline } from '../../common/Headline'
import { Divider } from '../../common/Divider'
import { ServiceHealthTimeline } from '../../ServiceHealthTimeline'
import { getLocaleTimestamp, sortByTsDesc } from '../../../helpers/statusCardHelpers'
import { executeWithToast } from '../../../helpers/executionUtils'
import axios from 'axios'

export interface IServiceHealthHistoryProps {
  serviceName: string
}

const toastConfig = {
  failureMessage: 'Could not load service health'
}

export const ServiceHealthHistory: React.FC<IServiceHealthHistoryProps> = ({ serviceName }) => {
  const [serviceHealth, setServiceHealth] = useState<IServiceHealth[]>([])
  const healthItems = useMemo(
    () =>
      serviceHealth
        .sort((a, b) => sortByTsDesc(a.healthStatusTS, b.healthStatusTS))
        .map((item) => ({
          health: item.healthStatus.status,
          timestamp: getLocaleTimestamp(item.healthStatusTS)
        })),
    [serviceHealth]
  )

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    const fetchHistory = async () => {
      const response = await API.getServiceHealth(serviceName, undefined, cancelToken)
      if (response) {
        setServiceHealth(response.serviceHealth)
      }
    }

    executeWithToast(fetchHistory, toastConfig)

    return () => {
      cancelToken.cancel()
    }
  }, [serviceName])

  return (
    <div>
      <Headline>Health History Timeline</Headline>
      <Divider />
      <ServiceHealthTimeline items={healthItems} />
    </div>
  )
}
