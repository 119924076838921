import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../../../config/auth'
import { useEffect, useState } from 'react'

export interface IPrivateOutletProps {
  challengePath?: string
  publicPath?: string
}

export const PrivateOutlet = ({ challengePath, publicPath }: IPrivateOutletProps) => {
  const { loggedIn, isAuthenticated } = useAuth()
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    isAuthenticated().then(() => {
      setLoading(false)
    })
  }, [isAuthenticated])

  if (loading) {
    return <div></div> // TODO: add better loading indicator here
  }

  if (loggedIn) {
    return publicPath ? <Navigate to={publicPath} /> : <Outlet />
  }
  return challengePath ? <Navigate to={challengePath} state={location} /> : <Outlet />
}
