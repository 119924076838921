import React, { Fragment, useEffect, useMemo, useState, useCallback } from 'react'
import { IAppVersion  } from '../../../interfaces'
import { fetchReleases } from './releasesService'
import { Headline } from '../../common/Headline'
import { Divider } from '../../common/Divider'
import { Card } from '../../common/Card'
import { Button } from '../../common/Button'
import { ColorText } from '../../common/ColorText'
import { executeWithToast } from '../../../helpers/executionUtils'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { getLocaleTimestamp } from '../../../helpers/statusCardHelpers'
import API from '../../../services/bffApi'
import { ConfirmationModal } from '../../ConfirmationModal'
import { useObsStore } from '../../../store'

export interface IServiceReleaseHistoryProps {
  serviceName: string
}

export interface IServiceReleaseTimelineProps {
  releases: { [key: string]: IAppVersion[] }
}

export interface IServiceReleaseViewProps {
  release: IAppVersion
  nextRelease?: IAppVersion
}


const getNextAvailableEnv = (env: string) => {
  const environments = ['dev', 'pp', 'p'];
  return environments[environments.indexOf(env) + 1] as 'pp' | 'p'
}

const toastConfigDeploy = {
  failureMessage: 'Could not deploy release',
  successMessage: 'Release sent to Github Action'
}

const ServiceRelease: React.FC<IServiceReleaseViewProps> = ({ release }) => {
  const nextReleaseEnv = getNextAvailableEnv(release.env);
  const [confirmClearModalOpen, setConfirmClearModalOpen] = useState<boolean>(false)
  const openModal = useCallback(() => setConfirmClearModalOpen(true), [setConfirmClearModalOpen])
  const closeModal = useCallback(() => setConfirmClearModalOpen(false), [setConfirmClearModalOpen])
  const [featureFlags] = useObsStore(
    useCallback((state) => [state.featureFlags], [])
  )

  const handleDeploy = () => {
    console.info(`Promoting ${release.commitSha} from: ${release.env} to: ${nextReleaseEnv}`)
    const body = {
      namespace: release.namespace,
      module: release.module,
      ref: release.commitSha,
      target: nextReleaseEnv,
      promoteFrom: release.env as 'dev' | 'pp' | 'p'
    }

    executeWithToast(async () => await API.postRelease(release.serviceName, body), toastConfigDeploy)
    closeModal()
  }

  const isDeployabe = [release.namespace, release.module, release.commitSha, nextReleaseEnv, release.env].every(value => value)

  return (
      <Card
        open={true}
        collapsible={true}
        title={`Deploy to ${release.env}`}
        color={'bg-lime-700'}
        style={{ width: 'calc(25vh + 50px)', paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem' }}
      >
        <div className='p-6 bg-white w-full rounded-bl-lg border border-gray-200 shadow-xl dark:bg-gray-800 dark:border-gray-700'>
          <div className='text-left'>
            <p className='mb-3 font-normal text-gray-700 dark:text-gray-300'>
              <b>Commit: </b>
              <ColorText color={'black'} weight='bold' uppercase>
                {release?.commitSha.substr(0, 7)}
              </ColorText>
            </p>
            <p className='mb-3 font-normal text-gray-700 dark:text-gray-300'>
              <b>Released At: </b>
              <ColorText color={'black'} weight='bold' uppercase>
                {getLocaleTimestamp(release.completedAt)}
              </ColorText>
            </p>
            <p className='mb-3 font-normal text-gray-700 dark:text-gray-300'>
              <b>Deployed by: </b>
              <ColorText color={'black'} weight='bold' uppercase>
                {release.deployedBy}
              </ColorText>
            </p>
            <a
              className='text-center underline inline-flex items-center font-medium'
              target='_blank'
              rel='noreferrer'
              href={release.diffLink}
            >
              <span className='mr-1'>Released Changeset</span>
              <ArrowTopRightOnSquareIcon className='text-gray-900 dark:text-gray-200 w-5 h-5' />
            </a>
            {featureFlags['enable-new-releases'] && nextReleaseEnv &&  <div className='flex justify-center pt-5'>
              <Button clickHandler={openModal} label={isDeployabe ? "Deploy" : "Not Deployable"} disabled={!isDeployabe} primary />
            </div>}
          </div>
        </div>
        <ConfirmationModal
          modalOpen={confirmClearModalOpen}
          closeModal={closeModal}
          heading={`Are you sure you want to promote ${release.serviceName}?`}
          message={`Please note that this will promote ${release.commitSha} from ${release.env} to ${nextReleaseEnv}.`}
          confirm={{ label: 'Deploy', action: handleDeploy }}
          cancel={{ label: 'Cancel', action: () => setConfirmClearModalOpen(false) }}
        />
      </Card>
  )
}

const ServiceReleaseTimeline: React.FC<IServiceReleaseTimelineProps> = ({ releases }) => {
  return (
    <div className='overflow-scroll pt-6' style={{ maxHeight: '60vh' }}>
      {Object.entries(releases).map(([sha, items], idx) => (
        <Fragment key={`${sha}-${idx}`}>
          <h5 className='text-left mb-2 text-md font-semibold tracking-tight text-gray-900 dark:text-white'>
            Commit: {sha}
          </h5>
          <Divider />
          <div className='flex items-center md:justify-around flex-col md:flex-row'>
            {items.map((value, i) => (
              <ServiceRelease release={value} nextRelease={items[i + 1]} key={`${sha}-${value.env}-${idx}`} />
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  )
}

const toastConfig = {
  failureMessage: 'Could not load service releases'
}

export const ServiceReleaseHistory: React.FC<IServiceReleaseHistoryProps> = ({ serviceName }) => {
  const [serviceVersions, setServiceVersions] = useState<{ [version: string]: IAppVersion[] }>({})
  const releases = useMemo(() => serviceVersions, [serviceVersions])

  useEffect(() => {
    const getReleases = async () => {
      const response = await fetchReleases(serviceName)
      if (response) {
        setServiceVersions(response)
      }
    }

    executeWithToast(getReleases, toastConfig)
  }, [serviceName])

  return (
    <div>
      <Headline>Service Release Timeline</Headline>
      <Divider />
      <ServiceReleaseTimeline releases={releases} />
    </div>
  )
}
